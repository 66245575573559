import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactDOMServer from 'react-dom/server';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { useRecoilValue, useRecoilState } from 'recoil';
import { currentUser, showSessionChat } from './recoil/atoms'
import * as workerTimers from 'worker-timers';
import { Badge, Button, CircularProgress, IconButton, Paper, Slider, Typography, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import clsx from 'clsx';
import queryString from 'query-string';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
// import PublishIcon from '@mui/icons-material/Publish'
// import TheatersIcon from '@mui/icons-material/Theaters'
import VideocamIcon from '@mui/icons-material/Videocam'
// import VideocamOffIcon from '@mui/icons-material/VideocamOff'
// import VideoCallIcon from '@mui/icons-material/VideoCall'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
// import ClearIcon from '@mui/icons-material/Clear'
// import CancelIcon from '@mui/icons-material/Cancel'
import StopIcon from '@mui/icons-material/Stop'
import GetAppIcon from '@mui/icons-material/GetApp'
// import { FixedSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
// import pptxgen from "pptxgenjs";
// import { RespondentListItem } from './RespondentListItem'
// import { StatsPanel } from './StatsPanel'
// import { ButtonPanel } from './ButtonPanel'
import { DialChart } from './DialChart'
import DialModal from './components/DialModal';
// import { DialContainer, DialBox } from './DialMeter'
// import worker from 'workerize-loader?inline!./sortWorker' // eslint-disable-line import/no-webpack-loader-syntax
// import livefilter from 'workerize-loader?inline!./filterWorker' // eslint-disable-line import/no-webpack-loader-syntax
import { wrap } from './promise-worker'
// import * as wasm from './wasm/wasm';
import { useOktaAuth } from '@okta/okta-react';
import { useLocalStorage } from './hooks/hooks'
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer';
import YoutubeVideoPlayer from './YoutubeVideoPlayer';
import { StatsHeader } from './StatsHeader';
import { CsvBuilder } from 'filefy'
import { saveAs } from 'file-saver';
import * as rasterizeHTML from 'rasterizehtml';
import moment from 'moment'
import config from './config.json'
import { updateSessionPromise, getZoomMeetingDetailsPromise } from './services/sessionsService';
import { updateProjectPromise } from './services/projectsService';
import { getTicktBoxStream } from './services/videoService'
import { getChatCredentialPromise } from './services/chimeService'
import './SessionDialPage.css';
import ChartSettingsDrawer from './ChartSettingsDrawer';
import TicktBoxVideoPlayer from './TicktBoxVideoPlayer';
import ChatDrawer from './components/chime/ChatDrawer';
import { StatsPopup } from './StatsPopup';
import { ButtonReasonsPopup } from './ButtonReasonsPopup';
import { SessionFiles } from './SessionFiles';
import ReplyIcon from '@mui/icons-material/Reply';
import { useTheme } from 'styled-components';


const PREFIX = 'SessionDialPage';

const classes = {
  app: `${PREFIX}-app`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  sceneMarkers: `${PREFIX}-sceneMarkers`,
  settingsButton: `${PREFIX}-settingsButton`,
  chartHeader: `${PREFIX}-chartHeader`,
  chartPaper: `${PREFIX}-chartPaper`,
  countDown: `${PREFIX}-countDown`,
  videoName: `${PREFIX}-videoName`
};

const Root = styled('div')((
  {
    theme, loaded
  }
) => ({
  height: '100%',
  width: '100%',
  [`& .${classes.app}`]: {
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden'
  },

  [`& .${classes.content}`]: {
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
  },

  [`& .${classes.sceneMarkers}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    height: '5%',
    minHeight: '25px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    justifyContent: 'center',
    background: 'black',
    color: 'white'
  },

  [`& .${classes.settingsButton}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translateX(85%)'
  },

  [`& .${classes.chartHeader}`]: {
    // display: 'flex',
    minHeight: '18%',
    position: 'relative',
    margin: '0 2rem 0 2rem',
    // overflowX: 'auto',
    // '&::-webkit-scrollbar': {
    //   height: '6px',
    //   backgroundColor: '#F5F5F5'
    // },
    // '&::-webkit-scrollbar-track': {
    //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    //   backgroundColor: '#F5F5F5',
    //   borderRadius: '6px'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   borderRadius: '6px',
    //   boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
    //   backgroundColor: '#888'
    // },
    // scrollbarWidth: 'thin',
    // scrollbarColor: '#888 #F5F5F5'
  },

  [`& .${classes.chartPaper}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    zIndex: 1,
    overflow: 'visible',
    background: loaded ? 'black' : 'white'
  },

  [`& .${classes.countDown}`]: {
    // marginTop: '3px',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '7px',
    cursor: 'pointer'
  },

  [`& .${classes.videoName}`]: {
    margin: '0 2.75rem 0 2.75rem'
  }
}));


const merge = require('lodash/merge')
const transform = require('lodash/transform')
const groupBy = require('lodash/groupBy')
const reduce = require('lodash/reduce')
const isEqual = require('lodash/isEqual')
const cloneDeep = require('lodash/cloneDeep')
const intersectionWith = require('lodash/intersectionWith')

const difference = (obj1, obj2) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const allowedColumns = [
  'id', 'internalVideoName', 'videoMarkers', 'chartVideoStart', 'chartVideoEnd', 'chartVideoOffset', 'dialChartSegments', 'chartConfig'
]

const keyify = (obj, prefix = '') => {
  return Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);
}

const exportCSV = (data, filename, columnHeaders) => {
  const headers = columnHeaders || reduce(data, (result, value, key) => {
    keyify(value).forEach(v => {
      result.add(v.charAt(0).toUpperCase() + v.slice(1).replace(/([A-Z])/g, ' $1'))
    })
    return result;
  }, new Set());
  const builder = new CsvBuilder(filename + ".csv");
  builder
    .setColumns([...headers])
    .addRows(data.map((rowData) => Object.values(rowData)))
    .exportFile();
}

const formatTime = (time, noMs) => {
  if (time < 0) return '\u2011\u2011:\u2011\u2011:\u2011\u2011'
  const s = Math.floor(time) % 60
  const ms = !noMs && (time - Math.floor(time))
  // const s = `${Math.floor(time) % 60}`.padStart(2, '0')
  const m = Math.floor(time / 60) % 60
  const h = Math.floor(time / 60 / 60)
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}`
  // return h ? `${h}:${m.toString().padStart(2, '0')}:${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}` : (m ? `${m.toString().padStart(2, '0')}:${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}` : ` :${ms ? (s + ms).toFixed(3).padStart(6, '0') : s.toString().padStart(2, '0')}`)
}

const colorOrder = [
  //lt blue  //red      //orange    //green   //pink     //yellow   //purple  //magenta   //cyan    //lime grn  //blue    //lt green
  '#0a8aff', '#ff0a10', '#ff7f0a', '#10ff0a', '#fa0aff', '#fffa0a', '#800aff', '#ff0a8a', '#0afffa', '#0aff80', '#0a0fff', '#8aff0a',
  '#0057a7', '#a70004', '#a75000', '#04a700', '#a400a7', '#a7a400', '#5000a7',/*'#a70057',*/'#00a7a4', '#00a750', '#0003a7', '#57a700',
  '#6cb9ff', '#ff6c70', '#ffb26c', '#70ff6c', '#fc6cff',/*'#fffc6c',*/'#b36cff', '#ff6cb9', '#6cfffc', '#6cffb3', '#6c6fff', '#b9ff6c',
]

// const colorOrder = [
//   "#00b0f0", //blue
//   "#ff1919", //red
//   "#ffa500", //orange
//   "#2aa655", //green
//   "#ff95ee", //pink
//   "#ecff00", //yellow
//   "#707bbb", //indigo
//   "#a14499", //purple
//   "#41d2c5", //teal
//   "#318ea0", //lt blue
// ]

const videoStyle = { height: '100%', width: '100%' }

const youtubeLiveData = {
  current: null
}

const getYoutubeLiveStatus = async (videoId, token, param = 'actualEndTime') => {
  try {
    if (youtubeLiveData.current && youtubeLiveData.current[param]) return youtubeLiveData.current
    const res = await fetch(`https://${config.rest.sessionGuestAPI}/video/youtube/live-status?videoId=${videoId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    const data = await res.json()
    youtubeLiveData.current = { ...data }
    return data
  } catch (err) {
    console.log(err)
    return null
  }
}

const defaultLineColors = {
  all: '#a70057',//'#92374D',
  tuneout: '#a70057',
  default: '#6C6FFF',//'#17768D',
  unselected: '#888888',
  Male: '#0a8aff', //'#0a0fff', //'#4D9DE0', //'#8594ca', '#deacae', '#bda175'
  Female: '#ff0a10', //'#FF7B9C', //'#8594ca', '#deacae', '#bda175'
}

let sortWorker;
let filterWorker;
let statsWorker;
let wasmWorker;

const SessionDialPage = React.memo(({ client, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const { search, state } = location;
  const sessionId = props.sessionId || routeParams?.sessionId;
  const projectId = props.projectId || routeParams?.projectId;
  const { id } = queryString.parse(search);
  // console.log('DIAL PAGE RENDER')
  const { oktaAuth } = useOktaAuth();
  const user = useRecoilValue(currentUser);
  const [showChat, setShowChat] = useRecoilState(showSessionChat(sessionId))
  const [hasStarted, setHasStarted] = useState(false);
  const [sessionLength, setSessionLength] = useState()
  const [sessionSegments, setSessionSegments] = useState([]);
  const [videoMarkers, setVideoMarkers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sessionStartTime, setSessionStartTime] = useState();
  const [sessionEndTime, setSessionEndTime] = useState()
  const [liveSession, setLiveSession] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [finished, setFinished] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const [replay, setReplay] = useState(false)
  const [dataKeys, setDataKeys] = useState([])
  const [virtualDataKeys, setVirtualDataKeys] = useState([])
  const [symbolKeys, setSymbolKeys] = useState([])
  const [graphType, setGraphType] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}graphtype`, 'line')
  const [isChartViewFull, setIsChartViewFull] = useState(true);
  const [segmentsMap, setSegmentsMap] = useState({});
  // const [showDataLine, setShowDataLine] = useState()
  const [showAll, setShowAll] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}showall`, false)
  const [showSegmentLine, setShowSegmentLine] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}showsegment`, { all: true })
  const [lineWidth, setLineWidth] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}linewidth`, {
    'all': 1.8,
    'respondents': 1.0,
    'default': 1.8
  })
  const [lineColor, setLineColor] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}linecolor`, defaultLineColors)
  const [markerColor, setMarkerColor] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}markercolor`, '#FFFC6C')
  const [markerWidth, setMarkerWidth] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}markerwidth`, 1.0)
  const [chartPrecision, setChartPrecision] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}chartprecision`, 1)
  const [scorePrecision, setScorePrecision] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}scoreprecision`, 0)
  const [videoOpacity, setVideoOpacity] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}videoopacity`, 1)
  // const [showMinutes, setShowMinutes] = useLocalStorage(`dialchart${sessionId}showminutes`, false)
  const [showMinutes, setShowMinutes] = useState(true)
  const [showMarkerLabels, setShowMarkerLabels] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}showmarkerlabels`, true)
  const [showSegmentLabels, setShowSegmentLabels] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}showsegmentlabels`, true)
  const [chartSize, setChartSize] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}chartsize`, 'm')
  const [chartData, setChartData] = useState([]);
  const [chartDomain, setChartDomain] = useState([])
  const [chartButtons, setChartButtons] = useState([]);
  // const [csvData, setCSVData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showInfoPanel, setShowInfoPanel] = useState(false)
  const [showButtonPanel, setShowButtonPanel] = useState(false)
  const [respondentKeys, setRespondentKeys] = useState([]);
  const [respondentData, setRespondentData] = useState({});
  const [count, setCount] = useState({})
  const [chartCurve, setChartCurve] = useState();
  const [videoSrc, setVideoSrc] = useState();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoBuffering, setVideoBuffering] = useState(false)
  // const [showVideo, setShowVideo] = useState(true);
  const [muted, setMuted] = useState(true);
  const [videoState, setVideoState] = useState();
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoIndex, setVideoIndex] = useState(0);
  const [internalVideoName, setInternalVideoName] = useState([])
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoEndTime, setVideoEndTime] = useState()
  // const [videoOffset, setVideoOffset] = useState(0)
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [dataAvailable, setDataAvailable] = useState(false)
  const [videoResumeTime, setVideoResumeTime] = useState(0)
  const [currentScene, setCurrentScene] = useState()
  const [exportAnchorEl, setExportAnchorEl] = useState()
  // const [ratio, setRatio] = useState()
  const [sessionDialogOpen, setSessionDialogOpen] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}dialog`, false) //useState(false);
  const [traceViewerOpen, setTraceViewerOpen] = useState(false)
  const [configData, setConfigData] = useState()
  const [videoCountdown, setVideoCountdown] = useState(1)
  const [chimeChatData, setChimeChatData] = useState()
  const [hasChat, setHasChat] = useState(!!(user.hasChat || (sessionId && user.chatState?.[sessionId])))
  const [chatNotification, setChatNotification] = useState(false)
  const [segmentFontSize, setSegmentFontSize] = useState(0)
  const [currentSegmentGroup, setCurrentSegmentGroup] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}segmentgroup`, null)
  const [currentSegment, setCurrentSegment] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}currentsegment`, null)
  const [currentSymbol, setCurrentSymbol] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}currentsymbol`, null)
  const [loadingZoom, setLoadingZoom] = useState(false);
  const [singleView, setSingleView] = useLocalStorage(`dialchart${projectId ? `project${projectId}` : `session${sessionId}`}singleview`, null)
  const [showFiles, setShowFiles] = useState(false);
  const [scoreBase, setScoreBase] = useState(100);

  const fullScreenMode = useFullScreenHandle();
  const theme = useTheme();

  const startTime = useRef(0);
  const videoLength = useRef(0);
  const contentLengthRef = useRef([]);
  const dialModeRef = useRef()
  const groupPeriodRef = useRef(30)
  const videoOffset = useRef(0)
  const videoStartTimeRef = useRef()
  const projectRef = useRef(!!projectId)
  const sessionRef = useRef(sessionId);
  const videoIndexRef = useRef(0);
  const sortDataRef = useRef({})
  const sortQueueRef = useRef([])
  const questionsRef = useRef([])
  const traceViewerLabel = useRef()
  // const manualSegmentRef = useRef(new Set())
  const crosstabRef = useRef(new Map())
  const livePlaybackRef = useRef()
  const inProgress = useRef()
  const videoTimeRef = useRef(currentVideoTime)
  // videoTimeRef.current = currentVideoTime
  const debounceRef = useRef()
  const isSeeking = useRef()
  const replayRef = useRef();
  const replayLength = useRef();
  const chartDataRef = useRef();
  const buttonRef = useRef();
  const liveDataRef = useRef();
  const liveButtonRef = useRef();
  const sampledDataRef = useRef();
  const sampledButtonRef = useRef();
  // const csvDone = useRef();
  const replayTimeRef = useRef();
  const sendMessageRef = useRef();
  const videoDivRef = useRef()
  const videoRef = useRef();
  const videoStateRef = useRef();
  const videoBufferRef = useRef();
  const currentVideoState = useRef();
  const loadedVideo = useRef()
  const isVideoLoaded = useRef(videoLoaded)
  const videoId = useRef()
  const playerRef = useRef()
  const respondentRef = useRef([])
  const disconnectedRef = useRef(new Set())
  const isFinished = useRef(false)
  const isLive = useRef(false)
  const isYoutube = useRef(false)
  const autoStop = useRef(false)
  const liveDataLoaded = useRef(false)
  const convertedLive = useRef(false)
  const guestData = useRef({})
  const sessionLoaded = useRef(false)
  const hasConnected = useRef(false)
  const resumeNeeded = useRef(false)
  const chartLoaded = useRef(false)
  const recount = useRef(false)
  const startedRef = useRef(hasStarted)
  // startedRef.current = hasStarted
  const precisionRef = useRef(chartPrecision)
  // precisionRef.current = chartPrecision
  const videoMarkersRef = useRef()
  const sceneRef = useRef()
  const pngChartRef = useRef()
  const pngButtonRef = useRef()
  const lineWidthRef = useRef(Object.keys(lineWidth))
  const getAverages = useRef()
  const pngSize = useRef()
  const groupedDataRef = useRef();
  const chartOptionsRef = useRef();
  const segmentsMapRef = useRef({})
  const viewRef = useRef(singleView);
  const segmentRef = useRef(currentSegment);
  const segmentGroupRef = useRef(currentSegmentGroup);
  const symbolRef = useRef(currentSymbol);

  const getSocketUrl = useCallback(() => {
    const socketUrl = `wss://${config.ws.dialViewerWS}?${projectId ? `projectId=${projectId}` : `sessionId=${sessionId}`}`
    const token = !!client ? user.token : oktaAuth.getAccessToken()
    return `${socketUrl}&access_token=${user.token || token}`
  }, [client, sessionId, projectId, oktaAuth, user.token])
  const STATIC_OPTIONS = useMemo(() => ({
    onOpen: () => {
      const message = {
        action: 'connection',
        // sessionId: sessionRef.current,
        reconnect: hasConnected.current,
        completed: (isFinished.current && !inProgress.current),
        clientTime: Date.now()
      }
      sendMessageRef.current(JSON.stringify(message));
      hasConnected.current = true
    },
    shouldReconnect: (closeEvent) => {
      console.log('WS CLOSED', closeEvent)
      // return !(closeEvent.code === 1000 || closeEvent.code === 1006)
      return true
    }, //CUSTOMIZE
    // onClose: () => {
    //   const message = {
    //     action: 'leave',
    //     sessionId: sessionRef.current,
    //     // clientTime: Date.now()
    //   }
    //   sendMessageRef.current(JSON.stringify(message));
    // }
  }), []);
  const { sendMessage, lastMessage } = useWebSocket(getSocketUrl, STATIC_OPTIONS);
  sendMessageRef.current = sendMessage;

  // ADD BACK WHEN ASSIGNING SESSIONS IS SUPPORTED
  // useEffect(() => {
  //   if (!client && (!user.fullAdmin && !user.assignedSessions.map(x => `${x}`).includes(sessionId))) {
  //     navigate('/')
  //   }
  // }, [user, client, sessionId])

  useEffect(() => {
    // console.log('INITIALIZING')
    const initWorkers = async () => {
      sortWorker = await wrap(new Worker(new URL('./sortWorker', import.meta.url)))
      filterWorker = await wrap(new Worker(new URL('./filterWorker', import.meta.url)))
      statsWorker = await wrap(new Worker(new URL('./statsWorker', import.meta.url)))

    }
    // const initWasm = async () => {
    //   const module = await WebAssembly.compileStreaming(fetch(wasmSrc))
    //   wasmWorker = await wasm.instantiate(module, {})
    // }
    initWorkers() //.then(initWasm)
    // sortWorker = worker()
    // filterWorker = livefilter()
    return () => {
      // console.log('CLEANING UP')
      sortWorker?.terminate()
      filterWorker?.terminate()
      statsWorker?.terminate()
      if (livePlaybackRef.current) workerTimers.clearInterval(livePlaybackRef.current)
    }
  }, [])

  useEffect(() => {
    projectRef.current = !!projectId
  }, [projectId])

  const configureGuestSortSegments = useCallback(() => {
    // CONFIGURE CHART SEGMENTS
    Object.entries(guestData.current).forEach(([registrationId, res]) => {
      if (res.state !== 'Ejected') {
        if (res.segments) {
          Object.entries(res.segments).forEach(([questionKey, answer]) => {
            if (!!sortDataRef.current.customKeys[questionKey]) {
              if (Array.isArray(answer)) {
                answer.forEach((ans) => {
                  if (!sortDataRef.current.customKeys[questionKey][ans]) sortDataRef.current.customKeys[questionKey][ans] = new Set() //[]
                  sortDataRef.current.customKeys[questionKey][ans].add(registrationId)
                })
              } if (!!answer && typeof answer === 'object') {
                Object.entries(answer).forEach(([opt, ans]) => {
                  if (!sortDataRef.current.customKeys[questionKey][`${opt} \u2011 ${ans}`]) sortDataRef.current.customKeys[questionKey][`${opt} \u2011 ${ans}`] = new Set() //[]
                  sortDataRef.current.customKeys[questionKey][`${opt} \u2011 ${ans}`].add(registrationId)
                })
              } else {
                if (!!sortDataRef.current.freeNumericGroups[questionKey]) {
                  const ans = parseInt(answer)
                  Object.entries(sortDataRef.current.freeNumericGroups[questionKey]).forEach(([group, range]) => {
                    if ((ans - range[0]) * (ans - range[1]) <= 0) {
                      if (!sortDataRef.current.customKeys[questionKey][group]) sortDataRef.current.customKeys[questionKey][group] = new Set() //[]
                      sortDataRef.current.customKeys[questionKey][group].add(registrationId)
                    }
                  })
                } else {
                  if (!sortDataRef.current.customKeys[questionKey][answer]) sortDataRef.current.customKeys[questionKey][answer] = new Set() //[]
                  sortDataRef.current.customKeys[questionKey][answer].add(registrationId)
                }
              }
            }
            Object.entries(sortDataRef.current.virtualSeg).forEach(([virtualKey, segment]) => {
              if (segment.dataKey !== 'segments' && questionKey === segment.dataKey) {
                if (segment.condition === '!==' ? (Array.isArray(answer) ? (answer.includes(segment.dataValue)) : (answer !== segment.dataValue)) : (Array.isArray(answer) ? (answer.includes(segment.dataValue)) : (answer === segment.dataValue))) {
                  if (!sortDataRef.current.customKeys[virtualKey][virtualKey]) sortDataRef.current.customKeys[virtualKey][virtualKey] = new Set()
                  sortDataRef.current.customKeys[virtualKey][virtualKey].add(registrationId)
                }
              }
            })
          })
          Object.entries(sortDataRef.current.virtualSeg).forEach(([virtualKey, segment]) => {
            if (segment.dataKey === 'segments') {
              segment.answers.forEach(answer => {
                let inGroup = false;
                let range;
                const match = answer.conditions.map(condition => {
                  switch (condition.condition) {
                    case "IS":
                    case "INCLUDES":
                      return res.segments[condition.dataSubKey] && (typeof res.segments[condition.dataSubKey] === 'object' ? Array.isArray(res.segments[condition.dataSubKey]) ? res.segments[condition.dataSubKey].includes(condition.answerKey) : res.segments[condition.dataSubKey][condition.optionKey] === condition.answerKey : res.segments[condition.dataSubKey] === condition.answerKey);
                    case "IS NOT":
                    case "EXCLUDES":
                      return !(res.segments[condition.dataSubKey] && (typeof res.segments[condition.dataSubKey] === 'object' ? Array.isArray(res.segments[condition.dataSubKey]) ? res.segments[condition.dataSubKey].includes(condition.answerKey) : res.segments[condition.dataSubKey][condition.optionKey] === condition.answerKey : res.segments[condition.dataSubKey] === condition.answerKey));
                    case "IN":
                      range = segment.freeNumeric ? condition.answerKey.replace(/([^-\S\d])/g, '').split('-').map(s => parseFloat(s)) : null
                      return range && ((parseInt(res.segments[condition.dataSubKey]) - range[0]) * (parseInt(res.segments[condition.dataSubKey]) - range[1]) > 0);
                    case "NOT IN":
                      range = segment.freeNumeric ? condition.answerKey.replace(/([^-\S\d])/g, '').split('-').map(s => parseFloat(s)) : null
                      return range && !((parseInt(res.segments[condition.dataSubKey]) - range[0]) * (parseInt(res.segments[condition.dataSubKey]) - range[1]) > 0);
                    case "GREATER OR =":
                      return (parseInt(res.segments[condition.dataSubKey]) >= parseInt(condition.answerKey));
                    case "LESS OR =":
                      return (parseInt(res.segments[condition.dataSubKey]) <= parseInt(condition.answerKey));
                    case "COUNT >=":
                      return Array.isArray(res.segments[condition.dataSubKey]) && res.segments[condition.dataSubKey].length >= parseInt(condition.answerKey);
                    case "COUNT <=":
                      return Array.isArray(res.segments[condition.dataSubKey]) && res.segments[condition.dataSubKey].length <= parseInt(condition.answerKey);
                    default:
                      return false;
                  }
                })
                if (answer.group === "CUSTOM") {
                  const condition = answer.customLogic || ""
                  const accumulator = {
                    level: 0,
                    operator: [],
                    not: [],
                    value: [],
                    match
                  }
                  try {
                    const result = [...condition.replace(/\s/g, '')].reduce((s, char, i, arr) => {
                      switch (char) {
                        case "&":
                        case "|":
                          s.operator[s.level] = char
                          break;
                        case "!":
                          s.not[s.level] = true
                          break;
                        case "(":
                          s.level++;
                          break;
                        case ")":
                          s.level--;
                        case "$":
                          let val = char === "$" ? s.match[parseInt(arr.join('').slice(i).match(/^[^\d]*(\d+)/)[1]) - 1] : s.value.pop()
                          if (s.not[s.level]) {
                            val = !val
                            s.not[s.level] = false
                          }
                          if (typeof s.value[s.level] !== 'boolean') {
                            s.value[s.level] = val
                          } else if (s.operator[s.level] === "&") {
                            s.value[s.level] = s.value[s.level] && val
                          } else if (s.operator[s.level] === "|") {
                            s.value[s.level] = s.value[s.level] || val
                          }
                          break;
                      }
                      return s
                    }, accumulator)
                    inGroup = !!result.value[0]
                  } catch (err) {
                    console.log("ERROR PARSING CUSTOM LOGIC", err)
                  }
                } else {
                  inGroup = match.reduce((p, c) => answer.group === 'ALL' ? p && c : p || c, answer.group === 'ALL')
                }
                if (inGroup) {
                  if (!sortDataRef.current.customKeys[segment.questionKey][answer.answer]) sortDataRef.current.customKeys[segment.questionKey][answer.answer] = new Set()
                  sortDataRef.current.customKeys[segment.questionKey][answer.answer].add(registrationId)
                }
              })
            }
          })
        }
        if (res.sessionId) {
          Object.entries(sortDataRef.current.virtualSeg).filter(([k, v]) => v.dataKey === 'sessionId').forEach(([virtualKey, segment]) => {
            if (!sortDataRef.current.customKeys[virtualKey][`Session ID ${res.sessionId}`]) {
              sortDataRef.current.customKeys[virtualKey][`Session ID ${res.sessionId}`] = new Set()
            }
            sortDataRef.current.customKeys[virtualKey][`Session ID ${res.sessionId}`].add(registrationId)
          })
        }
      }
    })
  }, [])

  const sortData = useCallback(({ message, resort = false, dataOnly = false, dataIndex }) => {
    let more = false;
    let values;
    if (message && message.scores) {
      console.log('MESSAGE TO SORT', message)
      more = message.more
      values = message.scores
    } else {
      values = message || []
    }

    const respondents = values.reduce((r, v) => r.concat(v.registrationId), [])

    if (resort) configureGuestSortSegments()

    // if (respondentRef.current.length !== respondents.length) {
    if (!dataOnly) setRespondentKeys(prev => [...new Set([...prev, ...respondents])])
    // }

    const dataToSort = [...sortQueueRef.current, ...values]

    if (more) {

      sortQueueRef.current = dataToSort

    } else {
      sortQueueRef.current = []

      if (dataOnly) {
        return sortWorker.sort(dataToSort, dialModeRef.current, dataIndex || 0, groupPeriodRef.current, guestData.current, disconnectedRef.current, sortDataRef.current, resort).then((sortedDataObj) => {
          if (sortedDataObj.timechart.length && isFinished.current && videoLength.current) {
            const last = JSON.parse(JSON.stringify(sortedDataObj.timechart[sortedDataObj.timechart.length - 1]))
            if (last.videoTime < contentLengthRef.current[dataIndex || 0] / 1000) {
              last.videoTime = contentLengthRef.current[dataIndex || 0] / 1000
              last.live = true;
              last.end = true;
              sortedDataObj.timechart.push(last)
            } else {
              sortedDataObj.timechart[sortedDataObj.timechart.length - 1].end = true
            }

            const rsLast = JSON.parse(JSON.stringify(sortedDataObj.resampledData[sortedDataObj.resampledData.length - 1]))
            if (rsLast.videoTime < Math.ceil(contentLengthRef.current[dataIndex || 0] / 1000)) {
              rsLast.videoTime = Math.ceil(contentLengthRef.current[dataIndex || 0] / 1000)
              rsLast.live = true;
              rsLast.end = true;
              sortedDataObj.resampledData.push(rsLast)
            } else {
              sortedDataObj.resampledData[sortedDataObj.resampledData.length - 1].end = true
            }
          }
          return sortedDataObj
        })
      } else {

        sortWorker.sort(dataToSort, dialModeRef.current, videoIndexRef.current, groupPeriodRef.current, guestData.current, disconnectedRef.current, sortDataRef.current, resort).then(({ timechart, buttons, resampledData, resampledButtons, groupedData, groupedButtons, newDisconnected }) => {

          // buttonPressRef.current = newButtons
          disconnectedRef.current = newDisconnected
          // const currentButtons = Object.keys(newButtons).map((resp) => {
          //   return { [resp]: newButtons[resp] }
          // })

          if (timechart.length && !more && isFinished.current && videoLength.current) {
            const last = JSON.parse(JSON.stringify(timechart[timechart.length - 1]))
            if (last.videoTime < videoLength.current) {
              last.videoTime = videoLength.current
              last.live = true;
              last.end = true;
              timechart.push(last)
            } else {
              timechart[timechart.length - 1].end = true
            }

            const rsLast = JSON.parse(JSON.stringify(resampledData[resampledData.length - 1]))
            if (rsLast.videoTime < Math.ceil(videoLength.current)) {
              rsLast.videoTime = Math.ceil(videoLength.current)
              rsLast.live = true;
              rsLast.end = true;
              resampledData.push(rsLast)
            } else {
              resampledData[resampledData.length - 1].end = true
            }
          }

          liveDataRef.current = timechart
          liveButtonRef.current = buttons
          sampledDataRef.current = resampledData
          sampledButtonRef.current = resampledButtons

          chartDataRef.current = precisionRef.current ? sampledDataRef.current : liveDataRef.current;
          buttonRef.current = precisionRef.current && dialModeRef.current !== 'symbol' ? sampledButtonRef.current : liveButtonRef.current

          if (liveDataRef.current.length && !(inProgress.current || isFinished.current)) { //LOOPING HERE SOMETIMES ON START
            console.log('checking if data is available for all viewers...')
            const ready = Object.entries(guestData.current).filter(([k, v]) => v.role === 'Viewer' && (v.userStatus === 'Ready' || v.userStatus === 'Watching')).reduce((p, [k, v]) => p && respondentRef.current.includes(k), true)
            console.log('are we ready?', ready)
            if (ready) setDataAvailable(true)
          }

          if (isFinished.current && !inProgress.current && !more) {
            console.log('SETTING CHART DATA', chartDataRef.current, buttonRef.current)
            setChartData(chartDataRef.current)
            setChartButtons(buttonRef.current)
          }

          if (sessionLoaded.current && !more && !chartLoaded.current) {
            if (startedRef.current && !isFinished.current && resumeNeeded.current) {
              const lastTime = timechart[timechart.length - 1]?.videoTime || 0
              console.log('RESUMING VIDEO AT: ', lastTime)
              setVideoResumeTime(lastTime)
              resumeNeeded.current = false
            }
            recount.current = resort
            if ((videoLength.current || (isLive.current && startedRef.current && !isFinished.current)) && liveDataLoaded.current) {
              console.log('SETTING LOADED')
              chartLoaded.current = true
              setLoaded(true)
            }
          }

        })

      }

    }
  }, [configureGuestSortSegments])

  // useEffect(() => {
  //   console.log('SHOW SEGMENT LINE CHANGED:', showSegmentLine)
  // }, [showSegmentLine])

  useEffect(() => {
    videoIndexRef.current = videoIndex || 0
  }, [videoIndex])

  useEffect(() => {
    if (!singleView) setGraphType(prev => !!prev ? prev.replace('100', '') : prev)
    viewRef.current = singleView
  }, [singleView])

  useEffect(() => {
    segmentRef.current = currentSegment
  }, [currentSegment])

  useEffect(() => {
    segmentGroupRef.current = currentSegmentGroup
  }, [currentSegmentGroup])

  useEffect(() => {
    symbolRef.current = currentSymbol
  }, [currentSymbol])

  useEffect(() => {
    setScoreBase(graphType === 'bar100' || graphType === 'area100' ? 100 : ((singleView ? count[currentSegment] : segmentsMap[currentSegmentGroup]?.reduce((p, c) => p + (count[c] || 0), 0)) || 0))
  }, [singleView, graphType, chartPrecision, currentSegment, currentSegmentGroup, count, segmentsMap])

  useEffect(() => {
    if (initialState) {
      console.log('INITIAL STATE:', initialState)
      if (initialState.id) {
        videoId.current = initialState.videoId
        liveDataLoaded.current = !initialState.isLiveSession
        contentLengthRef.current = initialState.contentLength || []
        dialModeRef.current = initialState.dialMode || 'score'
        isYoutube.current = initialState.videoPlatform === "YouTube"
        setConfigData({
          id: initialState.id,
          isLiveSession: initialState.isLiveSession,
          audienceSegments: initialState.audienceSegments,
          virtualSegments: initialState.virtualSegments,
          dialChartSegments: initialState.dialChartSegments,
          internalVideoName: initialState.internalVideoName,
          videoMarkers: initialState.videoMarkers,
          videoStartTime: initialState.videoStartTime,
          chartVideoStart: Number.isFinite(initialState.chartVideoStart) ? initialState.chartVideoStart : initialState.videoStartTime,
          chartVideoEnd: (initialState.chartVideoEnd || initialState.videoEndTime || (initialState.autoStop && initialState.autoStopTime) || (contentLengthRef.current[initialState.videoIndex || 0] / 1000) || (initialState.videoLength / 1000)) || videoLength.current,
          chartVideoOffset: initialState.chartVideoOffset || 0,
          contentLength: contentLengthRef.current,
          multiSection: initialState.multiSection,
          playlistLength: initialState.multiSection && contentLengthRef.current.length,
          chartConfig: initialState.chartConfig,
          symbolOptions: initialState.dialMode === 'symbol' && initialState.symbolOptions || [],
          dialMode: initialState.dialMode || 'score'
        })
        groupPeriodRef.current = initialState.chartConfig?.defaultAggregationInterval || 30
        const firstSymbol = initialState.dialMode === 'symbol' && (initialState.symbolOptions || [])[0]?.emoji || null
        if (initialState.chimeChatInstance && initialState.chimeChatChannels) {
          console.log('GOT CHIME CHAT DATA', {
            instance: initialState.chimeChatInstance,
            channels: initialState.chimeChatChannels
          })
          setChimeChatData({
            instance: initialState.chimeChatInstance,
            channels: initialState.chimeChatChannels
          })
        }
        if (initialState.chartConfig) {
          setGraphType(prev => initialState.chartUpdate ? initialState.chartConfig?.defaultChartType || prev : prev || initialState.chartConfig?.defaultChartType);
          setCurrentSegment(prev => initialState.chartUpdate ? initialState.chartConfig?.defaultSegment || prev : prev || initialState.chartConfig?.defaultSegment)
          setCurrentSymbol(prev => initialState.chartUpdate ? (initialState.chartConfig?.defaultEmoji || firstSymbol) || prev : prev || initialState.chartConfig?.defaultEmoji)
          setCurrentSegmentGroup(prev => initialState.chartUpdate ? initialState.chartConfig?.defaultEmojiSegmentGroup || prev : prev || initialState.chartConfig?.defaultEmojiSegmentGroup)
          setSingleView(prev => initialState.chartUpdate ? initialState.chartConfig?.segmentationMode === 'byEmoji' : typeof prev === 'boolean' ? prev : initialState.chartConfig?.segmentationMode === 'byEmoji');
          chartOptionsRef.current = initialState.chartConfig
        }
        if (initialState.dialChartSegments) {
          const segments = initialState.dialChartSegments
          const fullSegments = initialState.audienceSegments
          const symbols = (initialState.dialMode === 'symbol' && initialState.symbolOptions || []).map(x => x.emoji)
          crosstabRef.current = new Map()
          const sMap = segments.reduce((p, c) => {
            p[c.questionKey] = (c.answers || []).map(a => a.answer);
            if (c.hasCrosstab) {
              c.crosstabKeys.forEach(ctab => {
                p[`${c.questionKey} + ${ctab.key}`] = p[c.questionKey].reduce((px, cx) => {
                  Object.values(ctab.answers).map(cax => px.push(`${cx} + ${cax}`));
                  return px;
                }, []);
              })
            }
            return p
          }, { all: ['all'] })
          const segmentKeys = Object.keys(sMap)
          const fullSegmentsMap = fullSegments.reduce((p, c) => (c.hasCrosstab = false, p[c.questionKey] = c, p), {})
          const chartSegments = segments.map(seg => {
            const newSeg = [seg]
            if (seg.hasCrosstab) {
              seg.crosstabKeys.forEach(ck => {
                if (!segmentKeys.includes(ck.key)) {
                  newSeg.push(fullSegmentsMap[ck.key])
                  segmentKeys.push(ck.key)
                }
              })
            }
            return newSeg
          }).flat()

          questionsRef.current = chartSegments//segments
          // segmentsRef.current = fullSegments

          // SET UP SORTING
          sortDataRef.current = {
            virtualSeg: {},
            liveVirtualSeg: {},
            customKeys: {},
            crosstabKeys: {},
            customAverage: {},
            freeNumericGroups: {},
            symbolOptions: symbols.map(x => [x, 0]),
            segmentSplit: [...(initialState.audienceSegments || []), ...(initialState.virtualSegments || [])].reduce((p, c) => (p[c.questionKey] = c.answers.length, p), {})
          }
          chartSegments.forEach((question) => {
            // console.log('setting up for question ', question.questionKey)
            sortDataRef.current.customKeys[question.questionKey] = {}
            sortDataRef.current.customAverage[question.questionKey] = {}
            if (question.hasCrosstab) {
              sortDataRef.current.crosstabKeys[question.questionKey] = question.crosstabKeys.map(x => x.key)
              question.crosstabKeys.forEach(crosstab => {
                sortDataRef.current.customKeys[crosstab.key] = {}
                sortDataRef.current.customAverage[`${question.questionKey} + ${crosstab.key}`] = {}
                if (crosstab.virtual) {
                  if (['button', 'score'].includes(crosstab.dataKey) && !sortDataRef.current.liveVirtualSeg[crosstab.key]) {
                    sortDataRef.current.liveVirtualSeg[crosstab.key] = { ...crosstab }
                    sortDataRef.current.customKeys[crosstab.key][crosstab.key] = new Set()
                  } else if (!sortDataRef.current.virtualSeg[crosstab.key]) {
                    sortDataRef.current.virtualSeg[crosstab.key] = { ...crosstab }
                  }
                }
              })
            }
            if (question.freeNumeric) {
              sortDataRef.current.freeNumericGroups[question.questionKey] = question.answers.reduce((r, v, i, a, k = v.answer) => (r[k] = v.answer.replace(/([^-\S\d])/g, '').split('-').map(s => parseInt(s)), r), {});
            }
            if (question.virtual && !(sortDataRef.current.virtualSeg[question.questionKey] || sortDataRef.current.liveVirtualSeg[question.questionKey])) {
              if (['button', 'score'].includes(question.dataKey) && !sortDataRef.current.liveVirtualSeg[question.questionKey]) {
                sortDataRef.current.liveVirtualSeg[question.questionKey] = { ...question }
                sortDataRef.current.customKeys[question.questionKey][question.questionKey] = new Set()
              } else if (!sortDataRef.current.virtualSeg[question.questionKey]) {
                sortDataRef.current.virtualSeg[question.questionKey] = { ...question }
              }
            }
          })
          segmentsMapRef.current = sMap
          setSegmentsMap(sMap)
          setSessionSegments(segments)
          const chartKeys = []
          const vs = {}
          segments.forEach((segment) => {
            const virtualSegment = segment.virtual && segment.dataKey !== 'segments' ? segment.dataKey === 'sessionId' && initialState.sessionIds ? initialState.sessionIds.map(s => `Session ID ${s}`) : [segment.questionKey] : []
            const segmentAnswers = segment.matrix ? Object.entries(segment.answers).map(([o, a]) => a.filter(x => x.showOnChart).map(x => `${o} \u2011 ${x.answer}`)).flat() : segment.answers.filter(x => x.showOnChart).map(x => x.answer)
            chartKeys.push(...virtualSegment, ...segmentAnswers)
            if (segment.virtual && segment.dataKey !== 'segments') {
              vs[segment.questionKey] = {
                key: segment.questionKey,
                dataKey: segment.dataKey,
                dataValue: segment.dataValue,
                condition: segment.condition
              }
            }
            if (segment.hasCrosstab) {
              const crosstabs = segment.crosstabKeys.filter(x => !x.virtual).map(crosstab => fullSegments.find(x => x.questionKey === crosstab.key))
              const virtualCrosstabs = segment.crosstabKeys.filter(x => x.virtual)
              if (crosstabs.length) {
                const crosstabAnswers = crosstabs.reduce((p, c) => (p[c.questionKey] = c.matrix ? c.matrixKeys.map(o => c.answers.map(x => `${o.option} \u2011 ${x.answer}`)).flat() : c.answers.map(x => x.answer), p), {})
                segmentAnswers.forEach(ans => crosstabRef.current.set(ans, Object.entries(crosstabAnswers).map(([k, v]) => ({ [k]: v }))))
                console.log('SEGMENT ANSWERS', segmentAnswers, segmentAnswers.map(ans => Object.values(crosstabAnswers).flat().map(cax => `${ans} + ${cax}`)).flat())
                chartKeys.push(...segmentAnswers.map(ans => Object.values(crosstabAnswers).flat().map(cax => `${ans} + ${cax}`)).flat())
              }
              if (virtualCrosstabs.length) {
                const seg = segment.answers.filter(x => x.showOnChart).map(x => virtualCrosstabs.map(crosstab => {
                  if (crosstab.dataKey === 'sessionId' && initialState.sessionIds) {
                    return initialState.sessionIds.map(s => {
                      vs[`${x.answer} + Session ID ${s}`] = {
                        key: `${x.answer} + Session ID ${s}`,
                        dataKey: crosstab.dataKey,
                        condition: crosstab.condition
                      }
                      return `${x.answer} + Session ID ${s}`
                    })
                  } else {
                    vs[`${x.answer} + ${crosstab.key}`] = {
                      key: `${x.answer} + ${crosstab.key}`,
                      dataKey: crosstab.dataKey,
                      dataValue: crosstab.dataValue,
                      condition: crosstab.condition
                    }
                    return `${x.answer} + ${crosstab.key}`
                  }
                }).flat()).flat()
                chartKeys.push(...seg)
              }
            }
          })
          const segmentColors = colorOrder.filter(x => !((chartKeys.includes('Male') && x === '#0a8aff') || (chartKeys.includes('Female') && x === '#ff0a10')))
          // const showColors = [...chartKeys, ...symbols].reduce((p, c, i) => {
          //   p[c] = colors[i % colors.length]
          //   return p
          // }, {});//, { colors: {}, i: 0 })
          const keys = initialState.dialMode === 'symbol' ? Object.keys(sMap).reduce((p, c) => {
            // if (c === 'all') return p;
            sMap[c].forEach((s, idx) => p[s] = defaultLineColors[s] || colorOrder[idx % segmentColors.length])
            return p;
          }, {}) : [...chartKeys].reduce((p, c, i) => {
            p[c] = defaultLineColors[c] || segmentColors[i % segmentColors.length]
            return p
          }, {});
          const syms = [...symbols].reduce((p, c, i) => {
            p[c] = colorOrder[i % colorOrder.length]
            return p
          }, {});
          // console.log('showColors:', Object.keys(showColors).reduce((p,c) => ));
          // setLineColor(prev => {
          //   console.log('PREV:', prev);
          //   return Object.keys(showColors).reduce((p, c) => {
          //     p[c] = { ...(p[c] || {}), ...(typeof prev[c] === 'string' ? { _: prev[c] } : prev[c])}
          //     return p
          //   }, { })
          //   // return { ...showColors.colors, ...prev }
          // })
          setLineColor(prev => ({ ...prev, ...keys, ...syms }));
          const isSingleView = initialState.chartUpdate ? initialState.chartConfig?.segmentationMode === 'byEmoji' || true : viewRef.current
          const currentSeg = initialState.chartUpdate ? initialState.chartConfig?.defaultSegment || segmentRef.current : segmentRef.current || initialState.chartConfig?.defaultSegment
          const currentSym = initialState.chartUpdate ? (initialState.chartConfig?.defaultEmoji || firstSymbol) || symbolRef.current : symbolRef.current || initialState.chartConfig?.defaultEmoji
          const currentSegGrp = initialState.chartUpdate ? initialState.chartConfig?.defaultEmojiSegmentGroup || segmentGroupRef.current : segmentGroupRef.current || initialState.chartConfig?.defaultEmojiSegmentGroup
          setShowSegmentLine(prev => {
            const checkShow = {
              ...prev, ...['all', ...chartKeys].reduce((p, c) => {
                p[c] = symbols.length ? symbols.reduce((ps, cs) => (ps[cs] = (isSingleView ? c === currentSeg : (cs === currentSym && (sMap[currentSegGrp] || []).includes(c))), ps), {}) : (c === 'all' ? !chartKeys.length : true)
                return p
              }, {})
            }
            // Object.keys(prev).forEach((seg, i) => {
            //   if ((seg !== 'all' && !chartKeys.includes(seg)) || (symbols.length && i)) checkShow[seg] = symbols.length ? symbols.reduce((p, c) => (p[c] = false, p), {}) : false
            // })
            return checkShow
          })
          setShowAll(!chartKeys.length || initialState.dialMode === 'symbol')
          setVirtualDataKeys(vs)
          // console.log('CHART KEYS', chartKeys, symbolKeys)
          setDataKeys(['all', ...chartKeys])
          setSymbolKeys(initialState.dialMode === 'symbol' && initialState.symbolOptions || [])
        }
        if (initialState.internalVideoName) {
          setInternalVideoName(initialState.internalVideoName)
        }
        if (initialState.videoMarkers) {
          const markers = initialState.videoMarkers
          videoMarkersRef.current = markers.reduce((p, c) => {
            const time = ((c.markerHour || 0) * 3600) + ((c.markerMinute || 0) * 60) + (c.markerSecond || 0)
            p[c.videoIndex || 0] = {
              ...(p[c.videoIndex || 0] || {}),
              [time]: c.markerName
            }
            return p
          }, {})
          console.log('MARKERS!!!', markers, Array.isArray(markers), videoMarkersRef.current)
          setVideoMarkers(videoMarkersRef.current)
        }
        if (projectRef.current || (initialState.videoEndTime || initialState.sessionEndTime) || initialState.complete || !initialState.isLiveSession && (Date.now() >= Date.parse(initialState.sessionStartTime) + initialState.videoLength)) {
          isFinished.current = true;
          // if (initialState.chartVideoEnd || initialState.videoEndTime) {
          //   videoLength.current = initialState.chartVideoEnd || initialState.videoEndTime
          //   setVideoEndTime(initialState.videoEndTime)
          //   setSessionLength(initialState.chartVideoEnd || initialState.videoEndTime)
          // } else 
          if (initialState.sessionEndTime) {
            setSessionEndTime(Date.parse(initialState.sessionEndTime) || Date.parse(initialState.sessionStartTime) + initialState.videoLength)
          }
          setFinished(true);
        } else if (initialState.multiSection) {
          console.log('MULTI SECTION...........SETTING VIDEO INDEX', initialState.videoIndex)
          setVideoIndex(initialState.videoIndex || 0)
          videoIndexRef.current = initialState.videoIndex || 0
        }
        if (!!contentLengthRef.current.length || initialState.videoLength || initialState.videoEndTime || (initialState.autoStop && initialState.autoStopTime)) {
          autoStop.current = !!(initialState.autoStop && initialState.autoStopTime)
          const contentLength = initialState.videoEndTime || (initialState.autoStop && initialState.autoStopTime) || (contentLengthRef.current[videoIndexRef.current] / 1000) || (initialState.videoLength / 1000) // videotime in seconds, videoLength in miliseconds
          // console.log('CHECK HERE------------------------------------>', videoIndexRef.current, contentLengthRef.current, contentLengthRef.current[videoIndexRef.current] / 1000)
          videoLength.current = (initialState.chartVideoEnd || contentLength)
          console.log('VIDEO LENGTH IS..............', videoLength.current)
          setVideoEndTime(contentLength)
          setSessionLength(initialState.chartVideoEnd || contentLength)
        }
        if (Number.isFinite(initialState.chartVideoStart) || initialState.videoStartTime /*&& !initialState.liveStartTime*/) {
          setVideoStartTime(Number.isFinite(initialState.chartVideoStart) ? initialState.chartVideoStart : initialState.videoStartTime)
        } else if (initialState.liveStartTime || initialState.sessionStartTime) {
          startTime.current = Date.parse(initialState.liveStartTime || initialState.sessionStartTime)
          setSessionStartTime(Date.parse(initialState.liveStartTime || initialState.sessionStartTime))
        }
        if (initialState.started) {
          startedRef.current = true;
          resumeNeeded.current = true;
          setHasStarted(true);
        }
        // if (initialState.videoLength) {
        //   videoLength.current = initialState.chartVideoEnd || (initialState.videoLength / 1000) // videotime in seconds, videoLength in miliseconds
        //   setVideoEndTime((initialState.videoLength / 1000))
        //   setSessionLength(initialState.chartVideoEnd || (initialState.videoLength / 1000))
        // }
        if (initialState.chartVideoOffset) {
          // setVideoOffset(initialState.chartVideoOffset)
          videoOffset.current = initialState.chartVideoOffset
        }
        if (initialState.isLiveSession) {
          setLiveSession(true)
          isLive.current = true;
        }
      }
      if (initialState.chartUpdate) sortData({ resort: true })
      sessionLoaded.current = true
    }
  }, [initialState, setLineColor, setShowAll, setShowSegmentLine, setGraphType, setCurrentSegment, setCurrentSymbol, setCurrentSegmentGroup, setSingleView, sortData])

  const youtubeLiveStatus = useCallback(async (vid, param) => {
    const token = !!client ? user.token : oktaAuth.getAccessToken()
    const status = await getYoutubeLiveStatus(vid, token, param)
    console.log('LIVE VIDEO STATUS', status)
    return status
  }, [client, oktaAuth, user.token])

  useEffect(() => {
    console.log("HERE AND CHECKING...", liveSession, initialState?.videoId)
    if (liveSession && initialState?.videoId) {
      console.log('GETTING LIVE OFFSET')
      youtubeLiveStatus(initialState.videoId).then((status) => {
        console.log('CHECKING LIVE STATUS', status, initialState, sessionStartTime, sessionEndTime)
        // let lateStart = false;
        // if (!!status.actualStartTime && sessionStartTime && Date.parse(status.actualStartTime) < sessionStartTime) {
        // const stateStartTime = Date.parse(initialState.liveStartTime || initialState.sessionStartTime)
        if (!!status.actualStartTime && sessionStartTime && Date.parse(status.actualStartTime) < sessionStartTime) {
          // lateStart = true
          const startTime = (sessionStartTime - Date.parse(status.actualStartTime)) / 1000
          console.log('SETTING LIVE OFFSET', startTime)
          if (!(Number.isFinite(initialState.chartVideoStart) || initialState.videoStartTime)) setVideoStartTime(startTime)
          // setConfigData(prev => { return { ...prev, videoStartTime: startTime, chartVideoStart: Number.isFinite(prev.chartVideoStart) ? prev.chartVideoStart : startTime } })
        }
        // if (!initialState.chartVideoEnd) {
        if (!!status.actualEndTime) {
          if (sessionEndTime && Date.parse(status.actualEndTime) > sessionEndTime) {
            console.log('SESSION LENGTH 1', (sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
            // videoLength.current = (sessionEndTime - Date.parse(status.actualStartTime)) / 1000
            if (!initialState.videoEndTime) setVideoEndTime((sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
            if (!(initialState.chartVideoEnd || initialState.videoEndTime)) setSessionLength((sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
          } else {
            console.log('SESSION LENGTH 2', (Date.parse(status.actualEndTime) - Date.parse(status.actualStartTime)) / 1000)
            // videoLength.current = (Date.parse(status.actualEndTime) - Date.parse(status.actualStartTime)) / 1000
            if (!initialState.videoEndTime) setVideoEndTime((Date.parse(status.actualEndTime) - Date.parse(status.actualStartTime)) / 1000)
            if (!(initialState.chartVideoEnd || initialState.videoEndTime)) setSessionLength((Date.parse(status.actualEndTime) - Date.parse(status.actualStartTime)) / 1000)
          }
        } else if (!!sessionEndTime) {
          console.log('SESSION LENGTH 3', (sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
          // videoLength.current = (sessionEndTime - Date.parse(status.actualStartTime)) / 1000
          if (!initialState.videoEndTime) setVideoEndTime((sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
          if (!(initialState.chartVideoEnd || initialState.videoEndTime)) setSessionLength((sessionEndTime - Date.parse(status.actualStartTime)) / 1000)
        }
        // }
        if ((status.scheduledStartTime && !liveDataLoaded.current) || !!sessionEndTime) {
          liveDataLoaded.current = true
          convertedLive.current = !status.scheduledStartTime
          if (!!chartDataRef.current && !chartLoaded.current) {
            // setLoaded(true)
            console.log('SETTING LOADED (LIVE)')
            sortData({ resort: true })
          }
        }
      })
    }
    // if (sessionEndTime) {
    //   setFinished(true)
    // }
  }, [liveSession, initialState, sessionStartTime, sessionEndTime, youtubeLiveStatus, sortData])

  useEffect(() => {
    lineWidthRef.current = Object.keys(lineWidth)
  }, [lineWidth])

  useEffect(() => {
    const contentLength = contentLengthRef.current[videoIndex || 0]
    console.log('VIDEO INDEX CHANGE:', videoIndex, contentLength)
    if (contentLength) setSessionLength(contentLength / 1000)
    if (chartLoaded.current) sortData({ resort: true })
  }, [videoIndex, sortData])

  useEffect(() => {
    if (loaded && ((!respondentRef.current.length || !respondentKeys.length) || !isEqual(respondentRef.current, respondentKeys)) || (recount.current)) {
      const counts = {}

      const currentResp = respondentKeys.filter(key => respondentData[key] && respondentData[key].state !== 'Ejected')
      const allCount = currentResp.length
      counts.all = allCount

      Object.values(sortDataRef.current.customKeys).forEach(segmentGroup => {
        Object.entries(segmentGroup).forEach(([segment, set]) => {
          if (Object.keys(sortDataRef.current.liveVirtualSeg).includes(segment)) {
            counts[segment] = allCount
          } else {
            counts[segment] = intersectionWith([...set].filter(key => respondentData[key] && respondentData[key].state !== 'Ejected'), currentResp).length
          }
          if (crosstabRef.current.has(segment)) {
            crosstabRef.current.get(segment).forEach((crosstabGroup) => {
              Object.entries(crosstabGroup).forEach(([groupKey, crosstabs]) => {
                crosstabs.forEach(crosstab => counts[`${segment} + ${crosstab}`] = sortDataRef.current.customKeys[groupKey][crosstab] ? intersectionWith(intersectionWith([...set], [...sortDataRef.current.customKeys[groupKey][crosstab]]), currentResp).length : 0)
              })
            })
          }
        })
      })
      console.log('COUNTS', counts)
      const shouldShowAll = !Object.keys(counts).filter(x => x !== 'all').length
      setShowAll(prev => prev || shouldShowAll)
      setCount(counts)
      setShowSegmentLine(prev => {
        const newShow = { ...prev }
        newShow.all = newShow.all || shouldShowAll
        // Object.keys(prev).forEach(seg => {
        //   if (typeof newShow[seg] === 'boolean') {
        //     // if (!manualSegmentRef.current.has(seg) && dataKeys.filter(x => x !== 'all').includes(seg)) newShow[seg] = true
        //     if (!counts[seg] || (seg === 'all' && !(shouldShowAll || newShow[seg]))) newShow[seg] = false
        //   }
        //   // else {
        //   //   Object.keys(newShow[seg]).forEach(sym => {
        //   //     // if (!manualSegmentRef.current.has(`${seg}\u1690${sym}`)) newShow[seg][sym] = true    // do we need this? still don't understand why it's there? (not an ideal soln)
        //   //     if (!counts[seg] || (seg === 'all' && !(shouldShowAll || newShow[seg][sym]))) newShow[seg][sym] = false
        //   //   })
        //   // }
        // })
        return newShow
      })
      respondentRef.current = respondentKeys
      recount.current = false
    }
    // respondentRef.current = respondentKeys
  }, [loaded, respondentKeys, respondentData, dataKeys, setShowAll, setShowSegmentLine])

  useEffect(() => {
    if (lastMessage !== null) {
      // const messageTime = Date.now()
      const message = JSON.parse(lastMessage.data)
      // console.log('RECEIVED MESSAGE', message)
      if ((!sessionLoaded.current || message.chartUpdate) && message.id) {
        if (message.chartUpdate) {
          chartLoaded.current = false
          setMuted(true)
          setLoaded(false)
          setSaving(false)
          setReplay(false)
        }
        console.log('INITIAL MESSAGE', message)
        const customButtons = [
          ...(message.includeTuneOut ? [{
            key: 'R',
            label: 'Tune Out',
            shape: 'triangleDown',
            totalColor: '#E15554',
            showTotal: true
          }] : []),
          ...(message.includeShoutOuts ? [{
            key: 'S',
            label: 'Comment',
            multiPress: true,
            shape: 'circle'
          }] : []),
          ...(message.includeBreaks ? [{
            key: 'B',
            label: 'Break',
            multiPress: true,
            shape: 'square'
          }] : []),
        ]
        console.log('CUSTOM BUTTON CONFIG', customButtons)
        message.customButtons = customButtons.reduce((p, c) => (p.set(c.key, c)), new Map())
        setInitialState(message)
      } else if (message.guests) {
        guestData.current = message.guests.reduce((r, v, i, a, k = v.id) => {
          r[k] = v
          return r
        }, guestData.current)
        if (!message.more) {
          configureGuestSortSegments()
          setRespondentData(guestData.current)
          // const names = initialState.guests.reduce((r, v, i, a, k = v.id) => (r.push({ id: `${v.id}`, name: v.alias }), r), [])
          // setRespondentNames(names)
        }
      } else if (message.scores) {
        sortData({ message })
      } else if (message.messageType === 'startSession') {
        startedRef.current = true;
        message.guestConnections.forEach((connection) => guestData.current[connection.userId] = merge(guestData.current[connection.userId], connection))
        if (message.videoStartTime) {
          setVideoStartTime(message.videoStartTime)
          setConfigData(prev => { return { ...prev, videoStartTime: message.videoStartTime } })
        } else {
          setSessionStartTime(Date.parse(message.sessionStartTime))
        }
        // setWaiting(false)
        setVideoIndex(message.videoIndex || 0)
        setHasStarted(true);
      } else if (message.messageType === 'liveStart') {
        startTime.current = Date.parse(message.liveStartTime)
        if (message.videoStartTime) {
          setVideoStartTime(message.videoStartTime)
          setConfigData(prev => { return { ...prev, videoStartTime: message.videoStartTime } })
        } else {
          setSessionStartTime(Date.parse(message.liveStartTime))
        }
      } else if (message.messageType === 'guestsEjected') {
        console.log('EJECTING GUESTS: ', message.guestIds)
        message.guestIds.forEach(gid => {
          if (guestData.current[`${gid}`]) guestData.current[`${gid}`].state = 'Ejected'
        })
        recount.current = true
        setRespondentData(guestData.current)
        sortData({ resort: true })
      } else if (message.messageType === 'stopSession' || message.messageType === 'complete') {
        console.log('SESSION HAS COMPLETED!!!', message)
        isFinished.current = true;
        if (message.videoEndTime) {
          videoLength.current = message.videoEndTime
          setSessionLength(message.videoEndTime)
        } else if (message.sessionEndTime) {
          setSessionEndTime(Date.parse(message.sessionEndTime))
        }

        if (liveDataRef.current.length && sampledDataRef.current.length && videoLength.current) {

          const last = JSON.parse(JSON.stringify(liveDataRef.current[liveDataRef.current.length - 1]))
          last.videoTime = videoLength.current
          last.live = true;
          last.end = true;
          liveDataRef.current.push(last)

          const rsLast = JSON.parse(JSON.stringify(sampledDataRef.current[sampledDataRef.current.length - 1]))
          rsLast.live = true;
          rsLast.end = true;
          sampledDataRef.current.push(last)

          chartDataRef.current = precisionRef.current ? sampledDataRef.current : liveDataRef.current;
          // setFinished(true);

          if (!(inProgress.current || replayRef.currrent)) {
            console.log('FINAL CHART UPDATE', chartDataRef.current)
            setChartData(chartDataRef.current)
            setFinished(true)
          }
        }
      } else if (message.messageType === 'chatMessage') {
        setHasChat(true)
      } else if (message.messageType === 'resetSession') {
        window.location.reload()
      } else {
        console.log('CHECK: ', message)
      }
    }
  }, [lastMessage, configureGuestSortSegments, sortData]);

  // useEffect(() => {
  //   if (loaded) {
  //     if (finished) getWebSocket().close(1000);
  //   }
  // }, [loaded, finished]);

  const onVideoTimeChange = useCallback((event) => {
    const playback = inProgress.current || replayRef.current
    const time = videoId.current ? isYoutube.current ? playerRef.current?.getCurrentTime?.() || 0 : event.target.player.currentTime() : event.target.currentTime * 1000
    if (!videoBufferRef.current) {
      if ((!debounceRef.current || (Math.abs(time - debounceRef.current) >= 0.85)) && !!playback) playback(time - videoOffset.current)
      if (!isSeeking.current) setCurrentVideoTime(time)
    }
  }, [])

  useEffect(() => {
    const isLoaded = ((loadedVideo.current || videoId.current) ? videoLoaded : true) && loaded
    if (isLoaded) {
      console.log('LOADED')
      if (hasStarted && !isFinished.current) {
        console.log('SESSION IS IN PROGRESS')
        if (dataAvailable) {
          console.log('DATA AVAILABLE - STARTING VIDEO + GRAPH')

          startTime.current = sessionStartTime;

          const collect = (time) => {
            debounceRef.current = time

            filterWorker.filter(time, chartDataRef.current, buttonRef.current).then(({ newData, newButtons, last }) => {

              if (last && !last.end) {
                // const last = timechart[timechart.length - 1]
                // last.videoTime = isFinished.current ? videoLength.current : (time || ((Date.now() - startTime.current) / 1000))
                last.videoTime = time || ((Date.now() - startTime.current) / 1000)

                // last.buttons = Object.keys(buttonPressRef.current).map((resp) => {
                //   return { [resp]: buttonPressRef.current[resp] }
                // })
                // last.buttons = currentButtons
                // last.buttons = 0
                last.live = true;
                newData.push(last)
              }

              if ((isLive.current || autoStop.current) && last && last.end && (videoLength.current && time >= videoLength.current)) {
                setFinished(true)
              } else {
                requestAnimationFrame(() => {
                  setChartData(newData)
                  setChartButtons(newButtons)
                })
              }

            })

            // if ((time >= videoLength.current)/*|| isFinished.current*/) {
            //   chartDataRef.current.push({ ...last, end: true })
            //   // setFinished(true) //ALLOW LIVE GRAPH TO CONTINUE
            // }

          }

          if (loadedVideo.current || videoId.current) {
            setVideoState('playing')
            inProgress.current = collect//(t) => requestAnimationFrame(() => replay(t))
            if (isYoutube.current) livePlaybackRef.current = workerTimers.setInterval(onVideoTimeChange, 333)
          } else {
            // inProgress.current = workerTimers.setInterval(collect, 100)
            inProgress.current = true
            livePlaybackRef.current = workerTimers.setTimeout(collect, 100)
          }
        }
      }
    }
  }, [hasStarted, sessionStartTime, loaded, videoLoaded, dataAvailable, onVideoTimeChange,])

  useEffect(() => {
    videoStartTimeRef.current = videoStartTime + videoOffset.current
    if (sessionLoaded.current) setConfigData(prev => { return { ...prev, chartVideoStart: videoStartTime } })
  }, [videoStartTime])

  useEffect(() => {
    if (loaded && (finished || waiting)) {
      if (inProgress.current) {
        if (livePlaybackRef.current) {
          workerTimers.clearInterval(livePlaybackRef.current)
          livePlaybackRef.current = null
        }
        if (loadedVideo.current || videoId.current) {
          setVideoState('stopped')
          if (!videoLength.current) {
            videoLength.current = videoTimeRef.current
            setSessionLength(videoTimeRef.current)
          }
          setCurrentVideoTime(videoStartTimeRef.current || 0)
          setChartData(chartDataRef.current)
          setChartButtons(buttonRef.current)
        }
        inProgress.current = null;
      }
      isFinished.current = !!finished
    }
  }, [loaded, finished, waiting]);


  useEffect(() => {
    // console.log('REPLAY', replay, replayRef.current)
    if (replay & !replayRef.current) {

      setChartData([])
      setChartButtons([])

      replayLength.current = 0;
      replayTimeRef.current = Date.now();
      // buttonRef.current = {};
      // buttonPressRef.current = {}
      disconnectedRef.current = new Set()

      // if (loadedVideo.current) setVideoState('playing')

      const replayFn = (videoTime) => {
        const elapsedTime = videoTime || ((Date.now() - replayTimeRef.current) / 1000)
        debounceRef.current = elapsedTime

        filterWorker.filter(elapsedTime, chartDataRef.current, buttonRef.current).then(({ newData: replayData, newButtons: replayButtons, last }) => {

          if (replayData.length && ((elapsedTime + videoOffset.current) < videoLength.current)) {
            // const repeat = JSON.parse(JSON.stringify(replayData[replayData.length - 1])) //NEEDED FOR DEEP CLONE
            last.videoTime = elapsedTime
            // repeat.buttons = currentButtons
            // repeat.buttons = 0
            last.live = true;
            replayData.push(last)
          }
          if ((elapsedTime + videoOffset.current) >= videoLength.current) {
            setReplay(false)
          } else {
            requestAnimationFrame(() => {
              setChartData(replayData)
              setChartButtons(replayButtons)
            })
          }
        })
      }

      if (loadedVideo.current || videoId.current) {
        replayRef.current = replayFn//(t) => requestAnimationFrame(() => replay(t))
        if (isYoutube.current) livePlaybackRef.current = workerTimers.setInterval(onVideoTimeChange, 333)
      } else {
        // replayRef.current = workerTimers.setInterval(replayFn, 100)
        replayRef.current = true
        livePlaybackRef.current = workerTimers.setInterval(replayFn, 100)
      }

    } else {
      if (replayRef.current) {
        // if (!(loadedVideo.current || videoId.current) || isLive.current) workerTimers.clearInterval(replayRef.current)
        if (livePlaybackRef.current) workerTimers.clearInterval(livePlaybackRef.current)
        livePlaybackRef.current = null
        replayRef.current = null
        if (loadedVideo.current || videoId.current) {
          setVideoState('stopped')
          setCurrentVideoTime(videoStartTimeRef.current || 0)
        }
        setChartData(chartDataRef.current)
        setChartButtons(buttonRef.current)
      }
    }
  }, [replay, onVideoTimeChange])

  useEffect(() => {
    loadedVideo.current = videoSrc
    if (!videoSrc) {
      setVideoState(null)
      setVideoLoaded(false)
    }
  }, [videoSrc])

  useEffect(() => {
    isVideoLoaded.current = videoLoaded
  }, [videoLoaded])

  useEffect(() => {
    currentVideoState.current = videoState
    isSeeking.current = videoState === 'seeking'
  }, [videoState])

  // useEffect(() => {
  //   if (!!csvData.length) {
  //     csvDone.current(false)
  //   }
  // }, [csvData])

  // FOR MANUAL VIDEO LOADING FEATURE
  // const loadVideo = useCallback((event) => {
  //   const videoFile = event.target.files[0]
  //   // setVideoState('stopped')
  //   setShowVideo(true)
  //   setVideoSrc(videoFile)
  // }, [])

  const onVideoLoaded = useCallback((duration) => {
    if (isLive.current && convertedLive.current) {
      videoLength.current = duration
      sortData({ resort: true })
    }
    console.log('VIDEO DURATION', duration)
    setVideoDuration(duration)
  }, [])

  const onVideoReady = useCallback((event) => {
    console.log('ready', isVideoLoaded.current)
    if (!isVideoLoaded.current) {
      console.log('VIDEO READY')
      setVideoLoaded(true)
      if (!inProgress.current) setVideoState('stopped')
    }
  }, [])

  const onVideoError = useCallback((error) => {
    console.log(error)
    setVideoSrc(null)
  }, [])

  const onVideoEnded = useCallback((event) => {
    console.log('VIDEO ENDED!!!', !!inProgress.current, videoIndex, contentLengthRef.current.length - 1)
    if (replayRef.current) setReplay(false)
    if (inProgress.current) {
      if (contentLengthRef.current.length && videoIndex < contentLengthRef.current.length - 1) {
        console.log('WAITING FOR NEXT VIDEO START')
      } else {
        console.log('SETTING TO FINISHED')
        setFinished(true)
      }
    }
  }, [videoIndex])

  const showVideoScene = useCallback((show, time) => {
    const sceneTime = show ? time : (currentVideoTime - videoOffset.current)
    if (videoMarkersRef.current && videoState === 'stopped') {
      let scene;
      Object.keys(videoMarkersRef.current[videoIndex || 0] || {}).forEach((markerTime, i, a) => {
        if (sceneTime >= parseInt(markerTime)) {
          if (!a[i + 1] || sceneTime < a[i + 1]) scene = videoMarkersRef.current[videoIndex || 0][markerTime]
        }
      })
      if (sceneRef.current !== scene) setCurrentScene(scene)
    }
  }, [videoState, videoIndex, currentVideoTime])

  useEffect(() => {
    console.log('SETTING VIDEO length ref to', sessionLength)
    videoLength.current = sessionLength
    setConfigData(prev => { return { ...prev, chartVideoEnd: sessionLength } })
    // sortData({resort: true})
  }, [sessionLength])

  const handleClickExport = useCallback((event) => {
    event.currentTarget.blur();
    setExportAnchorEl(event.currentTarget);
  }, []);

  const handleCloseExport = useCallback(() => {
    setExportAnchorEl(null);
  }, []);

  useEffect(() => {
    sceneRef.current = currentScene
  }, [currentScene])

  useEffect(() => {
    videoTimeRef.current = currentVideoTime
    const sceneTime = currentVideoTime - videoOffset.current
    if (!currentVideoTime) debounceRef.current = currentVideoTime
    if (videoMarkersRef.current) {
      let scene;
      Object.keys(videoMarkersRef.current[videoIndex || 0] || {}).forEach((markerTime, i, a) => {
        if (sceneTime >= parseInt(markerTime)) {
          if (!a[i + 1] || sceneTime < a[i + 1]) scene = videoMarkersRef.current[videoIndex || 0][markerTime]
        }
      })
      if (sceneRef.current !== scene) setCurrentScene(scene)
    }
  }, [videoIndex, currentVideoTime])

  const changeLineWidth = useCallback((key, value) => {
    setLineWidth(prev => {
      const newLine = { ...prev }
      newLine[key] = value
      return newLine
    });
  }, [setLineWidth])

  const changeLineColor = useCallback((key, color) => {
    setLineColor(prev => {
      const newLine = { ...prev }
      newLine[key] = (color && color.hex) || null
      return newLine
    });
  }, [setLineColor]);

  const handleVideoSeek = useCallback((event, newValue) => {
    // isSeeking.current = true
    if (videoState !== 'seeking') {
      setVideoState('seeking')
      videoStateRef.current = videoState === 'stopped' ? 'paused' : videoState
      if (videoState === 'stopped') setReplay(true)
    }
    videoId.current ? playerRef.current.currentTime ? playerRef.current.currentTime(newValue) : playerRef.current.seekTo(newValue) : videoRef.current.video.currentTime = newValue
    setCurrentVideoTime(newValue)
  }, [videoState])

  const changeVideoCurrentTime = useCallback((event, newValue) => {
    // isSeeking.current = false
    setVideoState(videoStateRef.current)
  }, [])

  // FOR GUEST-LEVEL DATA
  // const handleSelect = useCallback((selected) => {
  //   // console.log('click', selected)
  //   setShowDataLine(prev => prev === selected.id ? null : selected.id)
  // }, [])

  const handleSelectSegment = useCallback((event, ans = [], sym = [], exclusive) => {
    if (event) event.currentTarget.blur();
    // console.log('SELECTING', answers, symbols, exclusive)
    // answers.forEach(answer => {
    //   if (symbols.length) {
    //     symbols.forEach(symbol => manualSegmentRef.current.add(`${answer}\u1690${symbol}`))
    //   } else {
    //     manualSegmentRef.current.add(answer)
    //   }
    // })
    const answers = ans.filter(x => !!x)
    const symbols = sym.filter(x => !!x)
    setShowSegmentLine(prev => {
      const show = {
        ...prev, ...(exclusive ? dataKeys.reduce((p, c) => {
          p[c] = symbols.length ? symbolKeys.reduce((p, c) => (p[c.emoji] = false, p), {}) : false
          return p
        }, {}) : {})
      }
      return {
        ...show,
        ...(answers.reduce((p, answer) => ({
          ...p,
          [answer]: symbols.length ? {
            ...show[answer],
            ...(symbols.reduce((p, c) => ({
              ...p,
              [c]: exclusive ? true : !(symbols.reduce((p, c) => (p || !!prev[answer][c]), false))
            }), {}))
          } : exclusive ? true : !prev[answer]
        }), {}))
      }
    })
  }, [setShowSegmentLine, dataKeys, symbolKeys])

  useEffect(() => {
    precisionRef.current = chartPrecision
    chartDataRef.current = precisionRef.current ? sampledDataRef.current : liveDataRef.current;
    buttonRef.current = precisionRef.current ? sampledButtonRef.current : liveButtonRef.current
    if (currentVideoState.current === 'stopped' || currentVideoState.current === 'paused') {
      setChartData(chartDataRef.current)
      setChartButtons(buttonRef.current)
    }
  }, [chartPrecision])

  const changeChartPrecision = useCallback((event, value) => {
    setChartPrecision(value)
  }, [setChartPrecision])

  const changeScorePrecision = useCallback((event, value) => {
    setScorePrecision(event.target.value)
    // setScorePrecision(value)
  }, [setScorePrecision])

  useEffect(() => {
    setChartCurve(!!chartPrecision ? 'linear' : 'stepAfter');
  }, [chartPrecision])

  const prepareCSV = useCallback(async (precision, opts) => {
    setExportAnchorEl(null);

    const baseData = !opts?.full ? await sortData({ resort: true, dataOnly: true, dataIndex: videoIndex }) : await Promise.all([...Array(configData.playlistLength || 1).keys()].map(dataIndex => sortData({ resort: true, dataOnly: true, dataIndex }))).then(dataArr => {
      return dataArr.reduce((p, c) => {
        Object.entries(c).forEach(([k, v]) => {
          if (Array.isArray(c[k])) {
            p[k] = [...(p[k] || []), ...c[k]]
          } else {
            p[k] = { ...(p[k] || {}) }
            Object.entries(c[k]).forEach(([sk, sv]) => {
              p[k][sk] = [...(p[k][sk] || []), ...c[k][sk]]
            })
          }
        })
        return p
      }, {})
    })

    const tog = dataKeys.filter(x => !!count[x])
    const tox = groupBy(Object.values(precision ? baseData.resampledButtons : baseData.buttons).flat(), 'videoTime')
    const chart = cloneDeep(precision ? baseData.resampledData : baseData.timechart)

    const data = opts?.respondents ? chart.map(x => {
      const forCSV = []
      respondentKeys.filter(key => guestData.current[key]).map(key => guestData.current[key]).forEach((respondent) => {
        if (respondent.state !== 'Ejected') {
          const row = {}
          if (opts?.full) row.videoIndex = x.videoIndex + 1
          row.respondentId = respondent.id
          row.respondentName = respondent.firstName + ' ' + respondent.lastName
          row.respondentEmail = respondent.email
          if (respondent.segments) {
            Object.entries(respondent.segments).forEach(([k, v]) => {
              //TODO: ADD SUPPORT FOR MATRIX
              row[k] = Array.isArray(v) ? v.join(', ') : v
            })
          }
          row.sessionId = sessionId
          row.time = x.videoTime
          row.dialScore = x[respondent.id]
          const tob = x.buttons.reduce((p, c) => {
            return p || !!(c[respondent.id] && c[respondent.id].button === 'R')
          }, false)
          row[`Tune Out`] = tob ? 'Y' : 'N'
          forCSV.push(row)
        }
      })
      return forCSV
    }).flat() : transform(chart, (res, v) => {

      respondentKeys.forEach(k => delete v[k])
      Object.keys(v).forEach(k => {
        if (typeof v[k] === 'number') {
          v[k] = Math.round((v[k] * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)
        }
      })
      if (tox[v.videoTime]) {
        tox[v.videoTime].filter(tx => tx.videoIndex === v.videoIndex).forEach(tx => {
          v[`${tx.dataKey}Buttons`] = tx.buttons
          if (tx.dataKey === 'all') v.buttonReasons = tx.reasons.join(', ')
        })
      }

      res.push(v)
    }, []).map(x => {
      const nv = {}
      if (opts?.full) nv.videoIndex = x.videoIndex + 1
      nv.time = formatTime(x.videoTime)
      if (videoMarkersRef.current) {
        nv.scene = videoMarkersRef.current[x.videoIndex || 0]?.[Object.keys(videoMarkersRef.current[x.videoIndex || 0] || {}).filter((markerTime, i, a) => {
          return parseFloat(x.videoTime) >= parseInt(markerTime) && (!a[i + 1] || parseFloat(x.videoTime) < parseInt(a[i + 1]))
        })[0]] || ''
      }
      tog.forEach(tx => {
        if (dialModeRef.current === 'score') {
          if (tx === 'all') nv[tx] = x[tx]['all']
          else nv[tx] = x[tx]
        } else {
          Object.keys(x[tx]).forEach(sym => {
            let symLabel = symbolKeys.find(s => s.emoji === sym).tooltip;
            nv[`${tx}-${symLabel}`] = x[tx][sym].count;
          })
        }
        nv[`${tx}Buttons`] = x[`${tx}Buttons`] || 0
      })
      nv.buttonReasons = x.buttonReasons
      return nv
    })

    if (opts?.noExport) {
      return data
    } else {
      exportCSV(data, `${projectId ? `Project ${projectId}` : `Session ${sessionId}`} ${opts?.respondents ? 'Respondent' : 'Aggregate'} Data`)
    }

    // const headers = reduce(data, (result, value, key) => {

    //   keyify(value).forEach(v => {
    //     result.add(v.charAt(0).toUpperCase() + v.slice(1).replace(/([A-Z])/g, ' $1'))
    //   })
    //   return result;
    // }, new Set());
    // const builder = new CsvBuilder(`${projectId ? `Project ${projectId}` : `Session ${sessionId}`} ${opts?.respondents ? 'Respondent' : 'Aggregate'} Dial Data.csv`);
    // builder
    //   .setColumns([...headers])
    //   .addRows(data.map((rowData) => Object.values(rowData)))
    //   .exportFile();
  }, [respondentKeys, sessionId, projectId, dataKeys, symbolKeys, count, scorePrecision, configData, sortData])

  const prepareButtonPressExport = useCallback(() => {
    setExportAnchorEl(null);

    sortWorker.getRespondentData(videoIndexRef.current || 0, videoLength.current).then(({ respondents, resampledRespondents }) => {
      // const segKeys = configData.audienceSegments.map(x => x.questionKey)
      const segKeys = configData.audienceSegments.map(segment => (segment.matrix && segment.matrixKeys) ? segment.matrixKeys.map(mk => ({ ...segment, matrixKey: mk.option })) : segment).flat()

      const data = (chartPrecision ? resampledRespondents : respondents).reduce((p, c) => {
        if (Object.keys(c.buttons).length) {
          Object.entries(c.buttons).forEach(([rk, bp]) => {
            if (bp.button === 'R' || bp.button === 'S' || bp.button === 'B') {
              const nv = {}
              let time = '\u200B' + formatTime(c.videoTime);
              let start = chartPrecision ? '\u200B' + formatTime(Math.ceil(bp.start)) : '\u200B' + formatTime(bp.start);
              nv.time = bp.button !== 'B' ? time : start;
              if (videoMarkersRef.current) {
                nv.scene = videoMarkersRef.current[videoIndexRef.current || 0]?.[Object.keys(videoMarkersRef.current[videoIndexRef.current || 0] || {}).filter((markerTime, i, a) => {
                  let sceneTime = bp.button !== 'B' ? c.videoTime : bp.start;
                  return parseFloat(sceneTime) >= parseInt(markerTime) && (!a[i + 1] || parseFloat(sceneTime) < parseInt(a[i + 1]))
                })[0]] || ''
              }
              if (bp.button === 'R') nv.type = 'Tune Out'
              if (bp.button === 'B') nv.type = 'Break'
              if (bp.button === 'S') nv.type = 'Comment'

              // TODO: ADD END TIME
              if (bp.button !== 'B') nv.end = nv.time;
              else nv.end = time;

              if (bp.reason) {
                nv.comment = bp.reason.reason || "";
                nv[`Guest ID`] = bp.reason.gid;
              }

              segKeys.forEach(sk => {
                //TODO: ADD SUPPORT FOR MATRIX
                const v = guestData.current[rk]?.segments[sk.questionKey] || ""
                const l = sk.matrixKey ? `${sk.questionKey} - ${sk.matrixKey}` : sk.questionKey
                nv[l] = Array.isArray(v) ? v.join(', ') : (!!v && typeof v === 'object') ? v[sk.matrixKey] : v
              })
              p.push(nv)
            }
          })
        }
        return p
      }, [])

      console.log('data:', data);

      exportCSV(data, `${projectId ? `Project ${projectId}` : `Session ${sessionId}`} Button Data`)

    })

  }, [chartPrecision, projectId, sessionId, configData])

  const openTraceViewerDialog = useCallback(() => {
    setExportAnchorEl(null)
    setTraceViewerOpen(true)
  }, [])

  const prepareTraceViewer = useCallback(() => {
    // setExportAnchorEl(null)
    setTraceViewerOpen(false)
    sortWorker.getRespondentData(videoIndexRef.current || 0, videoLength.current).then(({ resampledRespondents }) => {

      const respIds = resampledRespondents.reduce((p, c) => (Object.keys(c).filter(k => !['disconnected', 'videoTime', 'buttons'].includes(k)).forEach(r => p.add(r)), p), new Set())
      const headers = ['VSR_Respondent_ID']
      const rowData = [...respIds].reduce((p, c) => (p[c] = { scores: [] }, p), {});

      resampledRespondents.forEach(x => {
        // headers.push(`${traceViewerLabel.current}r${x.videoTime}`)
        respIds.forEach(k => {
          // rowData[k].scores.push(x[k])
          const score = Number.isInteger(x[k]) ? x[k] : 'NULL'
          rowData[k].scores.push(`${x.videoTime}~${score}`)
          if (x.buttons[k] && x.buttons[k].button === 'R') {
            rowData[k].buttonTime = x.videoTime
          }
        })
      })

      let chunkLength = 0
      Object.keys(rowData).forEach(k => {
        const chunkedScores = []
        const chunkSize = 3000
        for (let i = 0; i < rowData[k].scores.length; i += chunkSize) {
          chunkedScores.push(rowData[k].scores.slice(i, i + chunkSize).join('|'))
        }
        if (chunkedScores.length > chunkLength) chunkLength = chunkedScores.length
        rowData[k].scores = chunkedScores
      })

      for (let z = 1; z <= chunkLength; z++) {
        headers.push(`${traceViewerLabel.current}_RatingOEr${z}`)
      }

      const builder = new CsvBuilder(`${projectId ? `Project ${projectId}` : `Session ${sessionId}`} Respondent Dial Data (TraceViewer)` + ".csv");
      builder
        .setColumns([...headers, `${traceViewerLabel.current}_ActionButtonSecond`])
        .addRows(Object.entries(rowData).map(([k, v]) => [k, ...v.scores, v.buttonTime]))
        .exportFile();
      traceViewerLabel.current = null;
    })
  }, [sessionId, projectId])

  const exportChartPNG = useCallback(async (includeTuneOuts) => {
    setExportAnchorEl(null);
    const { height, width } = pngSize.current
    if (pngChartRef.current) {
      const svgElement = pngChartRef.current(includeTuneOuts, getAverages.current(), { height, width })
      const svg = `<style>#graph-composed-chart {overflow: visible}</style>` + ReactDOMServer.renderToString(svgElement)
      const canvas = document.createElement('canvas');
      canvas.width = width + 10;
      canvas.height = height + 20;
      await rasterizeHTML.drawHTML(svg, canvas)
      saveAs(canvas.toDataURL('image/png', 1.0), `${projectId ? `Project ${projectId}` : `Session ${sessionId}`} ${includeTuneOuts ? ' + Tune Outs ' : ' '}Chart.png`);
    }
  }, [sessionId, projectId]);

  const exportTuneOutPNG = useCallback(async () => {
    setExportAnchorEl(null);
    const { height, width } = pngSize.current
    if (pngButtonRef.current) {
      const svgElement = pngButtonRef.current(getAverages.current(), { height, width })
      const svg = ReactDOMServer.renderToString(svgElement)
      const canvas = document.createElement('canvas');
      canvas.width = width + 10;
      canvas.height = height + 20;
      await rasterizeHTML.drawHTML(svg, canvas)
      saveAs(canvas.toDataURL('image/png', 1.0), `${projectId ? `Project ${projectId}` : `Session ${sessionId}`} Tune Out % Chart.png`);
    }
  }, [sessionId, projectId]);

  // TODO: PPT CHART EXPORT
  // const preparePPT = useCallback((data, event, done) => {
  //   setExportAnchorEl(null);
  //   const pres = new pptxgen();
  //   const slide = pres.addSlide();

  //   let dataChartAreaLine = [
  //     {
  //       name: "Actual Sales",
  //       labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //       values: [1500, 4600, 5156, 3167, 8510, 8009, 6006, 7855, 12102, 12789, 10123, 15121],
  //     },
  //     {
  //       name: "Projected Sales",
  //       labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //       values: [1000, 2600, 3456, 4567, 5010, 6009, 7006, 8855, 9102, 10789, 11123, 12121],
  //     },
  //   ];

  //   slide.addChart(pres.ChartType.line, dataChartAreaLine, { x: 1, y: 1, w: 8, h: 4 });

  //   pres.writeFile("Sample Presentation.pptx");

  // }, [respondentKeys])

  const toggleShowAll = useCallback((event) => {
    event.currentTarget.blur()
    setShowAll(prev => !prev)
  }, [setShowAll])

  const toggleShowMinutes = useCallback((event) => {
    event.currentTarget.blur()
    setShowMinutes(prev => !prev)
  }, [])

  const toggleShowMarkerLabels = useCallback((event) => {
    event.currentTarget.blur()
    setShowMarkerLabels(prev => !prev)
  }, [setShowMarkerLabels])

  const toggleShowSegmentLabels = useCallback((event) => {
    event.currentTarget.blur()
    setShowSegmentLabels(prev => !prev)
  }, [setShowSegmentLabels])

  const changeChartSize = useCallback((event, value) => {
    setChartSize(value)
  }, [setChartSize])

  useEffect(() => {
    pngSize.current = {
      height: chartSize === 's' ? 480 : chartSize === 'm' ? 720 : 1080,
      width: chartSize === 's' ? 852 : chartSize === 'm' ? 1280 : 1920
    }
  }, [chartSize])

  useEffect(() => {
    setShowSegmentLine(prev => {
      console.log('SHOW SEGMENT - FROM ALL')
      return { ...prev, all: showAll }
    })
  }, [showAll, setShowSegmentLine])

  const toggleDrawer = useCallback((event) => {
    event.currentTarget.blur()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(prev => !prev)
  }, []);

  const toggleInfoPanel = useCallback(async (event) => {
    event.currentTarget.blur()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setShowInfoPanel(prev => !prev)
  }, []);

  const toggleButtonPanel = useCallback(async (event) => {
    event.currentTarget.blur()
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setShowButtonPanel(prev => !prev)
  }, []);

  const onVideoOpacityChange = useCallback((e, v) => setVideoOpacity(v), [setVideoOpacity])

  const onSegmentFontChange = useCallback((e, v) => { setSegmentFontSize(v) }, [setSegmentFontSize])

  const onMarkerWidthChange = useCallback((e, v) => setMarkerWidth(v), [setMarkerWidth])

  const onMarkerColorChange = useCallback((color) => setMarkerColor(prev => (color && color.hex) || prev), [setMarkerColor])

  const handlePlayback = useCallback((event) => {
    event.currentTarget.blur();
    if (videoState === 'stopped') {
      setReplay(prev => !prev)
      if (loadedVideo.current || videoId.current) setVideoState('playing')
    } else {
      setVideoState(prev => prev === 'playing' ? 'paused' : 'playing')
    }
  }, [videoState]);

  const handleStop = useCallback((event) => {
    event.currentTarget.blur();
    setReplay(false)
  }, [])

  const handleNext = useCallback((event) => {
    // chartLoaded.current = false
    // setLoaded(false)
    setVideoIndex(prev => prev + 1)
  }, [])

  const handlePrevious = useCallback((event) => {
    // chartLoaded.current = false
    // setLoaded(false)
    setVideoIndex(prev => prev - 1)
  }, [])

  // const setVideoAspectRatio = useCallback((aspectRatio) => {
  //   setRatio(aspectRatio)
  // }, [])

  const handleVideoBuffer = useCallback((isBuffering) => {
    videoBufferRef.current = isBuffering
    setVideoBuffering(isBuffering)
  }, [])

  const onVideoPlay = useCallback(() => {
    setVideoState('playing')
  }, [])

  // const onVideoLoading = useCallback(() => {
  // }, [])

  // const ListRow = useCallback(({ data, index, style }) => {
  //   if (data.length) {
  //     return (
  //       <RespondentListItem style={[style]} resp={data[index]} show={showDataLine === data[index].id} select={handleSelect} />
  //     )
  //   } else {
  //     return (
  //       <Skeleton variant="text" style={style} animation="wave" className="list-item-loading" />
  //     )
  //   }
  // }, [showDataLine]);

  const submitChanges = useCallback((event, submitData) => {
    chartLoaded.current = false
    liveDataLoaded.current = !configData.isLiveSession
    setSaving(true)
    // setLoaded(false)
    setReplay(false)
    const data = difference(configData, submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {

      if (configData) data.id = configData.id
      for (let i in data) {
        if (!allowedColumns.includes(i) || [null, undefined, ''].includes(data[i])) delete data[i]
      }
      if (projectId) {
        delete data.chartVideoStart
        delete data.chartVideoEnd
      } else {
        if (data.chartVideoStart === configData.videoStartTime) data.chartVideoStart = null
        if (data.chartVideoEnd === videoEndTime) data.chartVideoEnd = null
        data.type = 'Content';
      }

      // FOR MYSQL DATA API
      if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
      if (data.dialChartSegments) data.dialChartSegments = JSON.stringify(data.dialChartSegments)
      if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName)
      if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)

      data.lastUpdatedBy = user.email
      const token = oktaAuth.getAccessToken()
      const submitPromise = projectId ? updateProjectPromise : updateSessionPromise
      submitPromise(data, token).then(() => {
        setSaving(false)
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          chartLoaded.current = true
          // setLoaded(true)
          setSaving(false)
        }
      })
    } else {
      chartLoaded.current = true
      // setLoaded(true)
      setSaving(false)
    }
    setSessionDialogOpen(false);
  }, [projectId, configData, videoEndTime, oktaAuth, user.email, setSessionDialogOpen]);

  const editSession = useCallback((event) => {
    event.currentTarget.blur();
    setSessionDialogOpen(true);
  }, [setSessionDialogOpen])

  const handleCloseSessionDialog = useCallback(() => {
    setSessionDialogOpen(false);
  }, [setSessionDialogOpen]);

  const handleCloseTraceViewerDialog = useCallback(() => {
    setTraceViewerOpen(false);
  }, []);

  const handleCloseInfoPanel = useCallback(() => {
    setShowInfoPanel(false);
  }, []);

  const handleCloseButtonPanel = useCallback(() => {
    setShowButtonPanel(false);
  }, []);

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur();
    fullScreenMode.active ? fullScreenMode.exit() : fullScreenMode.enter()
  }, [fullScreenMode]);

  const getChatCredentials = useCallback(async (chatInstance) => {
    const token = !!client ? user.token : oktaAuth.getAccessToken()
    return getChatCredentialPromise(user.chimeUsername, chatInstance, token, sessionId)
  }, [oktaAuth, user.chimeUsername, user.token, client, sessionId])

  const videoStreamData = useCallback(async (drmType) => {
    const token = !!client ? user.token : oktaAuth.getAccessToken()
    const status = await getTicktBoxStream(videoId.current, drmType, token)
    console.log('GOT TB STREAM DATA', status)
    return status
  }, [user, client, oktaAuth])

  const VideoPlayer = useMemo(() => {
    if (!initialState) return
    switch (initialState.videoPlatform) {
      case 'Brightcove':
      default:
        return BrightcoveVideoPlayer
      case 'YouTube':
        return YoutubeVideoPlayer
      case 'ticktBox':
        return TicktBoxVideoPlayer
      // case 'Twitch': 
      //     break;
    }
  }, [initialState])

  const allowedChatChannels = useMemo(() => {
    const channels = cloneDeep((chimeChatData && chimeChatData.channels) || [])
    return channels.filter(x =>
      (!!client ? x.userAccess.includes('Client') : x.staffAccess.filter(y => user.accessLevels.includes(y)))
    ).map(x => (x.userList = !x.userAccess.includes('Viewer'), x))
  }, [chimeChatData, user, client])

  useEffect(() => {
    if (chimeChatData) {
      setShowChat(prev => {
        if (!(chimeChatData.instance && !!(allowedChatChannels.length || hasChat))) {
          return false
        }
        else if (client && state?.openWithChat) {
          return { open: true }
        }
        else if (!prev) {
          return { open: false }
        }
        return prev
      })
    }
  }, [chimeChatData, allowedChatChannels, hasChat, setShowChat])

  const toggleChatPanel = useCallback(() => {
    setShowChat(prev => {
      return { open: !prev.open }
    })
  }, [setShowChat]);

  const handleShowFile = useCallback(() => {
    setShowFiles(true);
  }, []);

  const changeGraphType = useCallback((event) => {
    setGraphType(event.target.value)
  }, [])

  const handleHideFiles = useCallback(() => {
    setShowFiles(false);
  }, [])

  const toggleView = useCallback(() => {
    setSingleView(prev => !prev)
  }, [])

  const toggleChartView = useCallback(() => {
    setIsChartViewFull(prev => !prev)
  }, [])

  const changeCurrentSegment = useCallback((event) => {
    setCurrentSegment(event.target.value);
  }, [])

  const changeCurrentSymbol = useCallback((event) => {
    setCurrentSymbol(event.target.value)
  }, [])

  const changeCurrentSegmentGroup = useCallback((event) => {
    setCurrentSegmentGroup(event.target.value)
  }, [])

  const changeDomain = useCallback(setChartDomain, [])

  const handleReturn = useCallback(() => {
    navigate(`/client/project/${state?.pid}?id=${id}`);
  }, [navigate, state, id])

  useEffect(() => {
    if (showChat && showChat.open) setChatNotification(false)
  }, [showChat])

  const showChatNotification = useCallback((newMessages) => {
    if (!(showChat && showChat.open)) setChatNotification(!!newMessages)
  }, [showChat])

  const getZoomDetails = useCallback(async (meetingId, registrantId) => {
    const token = id;
    const data = await getZoomMeetingDetailsPromise(meetingId, registrantId, token);
    return data;
  }, [id]);

  const resetAllOptions = useCallback(() => {
    setGraphType(initialState.chartConfig.defaultChartType);
    setShowAll(initialState.dialMode === 'symbol');
    // setLineWidth({
    //   'all': 1.8,
    //   'respondents': 1.0,
    //   'default': 1.8
    // });
    // setMarkerWidth(1.0);
    setChartPrecision(1);
    // setScorePrecision(0);
    // setVideoOpacity(1);
    // setChartSize('m');
    setCurrentSegmentGroup(initialState.chartConfig.defaultEmojiSegmentGroup);
    setCurrentSegment(initialState.chartConfig.defaultSegment);
    setCurrentSymbol(initialState.chartConfig.defaultEmoji);
    setSingleView(initialState.chartConfig.segmentationMode);
  }, [initialState])

  const joinZoomMeeting = useCallback(async () => {
    setLoadingZoom(true);
    const zoomRegistrantId = user?.zoomRegistrantId || user?.zoomRegistrantIds[initialState?.id]
    const data = await getZoomDetails(initialState?.zoomMeetingId, zoomRegistrantId);
    window.open(data?.registration?.join_url || data?.details?.join_url, '_blank');
    setLoadingZoom(false);
  }, [user, initialState]);

  const headerAverageFn = useCallback((data, domain, keys) => {
    // console.log('~~~ GETTING AVERAGES ~~~', data.length, domain, keys)
    return statsWorker?.average(data, domain, keys, initialState?.dialMode || 'score')
  }, [initialState?.dialMode])

  return (
    <Root theme={theme} loaded={(loaded && !saving) && hasStarted}>
      <div className={clsx(classes.content, { [classes.contentShift]: (showChat && showChat.open) })}>
        {(loaded && configData && sessionDialogOpen) && <DialModal forProject={!!projectId} showVideoTimeFields={true} hideDialSettings={true} data={configData} onSubmit={submitChanges} open={sessionDialogOpen} handleClose={handleCloseSessionDialog} videoEndTime={videoEndTime || videoDuration} videoDuration={videoDuration} />}
        <FullScreen handle={fullScreenMode} className="body">
          {/*<div className="body">*/}
          <div className={classes.chartHeader}>
            <StatsHeader currentSegment={currentSegment} currentSymbol={currentSymbol} currentSegmentGroup={currentSegmentGroup} segmentsMap={segmentsMap} loaded={(loaded && !saving)} chartData={chartData} singleView={singleView} averageFn={headerAverageFn} showAll={showAll} dialMode={initialState?.dialMode || 'score'} dataKeys={dataKeys} virtualDataKeys={virtualDataKeys} symbolKeys={symbolKeys} buttons={chartButtons} scorePrecision={scorePrecision} chartDomain={chartDomain} chartStart={videoStartTime} chartEnd={sessionLength ? chartPrecision ? Math.round(sessionLength) : sessionLength : currentVideoTime} segments={sessionSegments} respondents={respondentKeys.map(key => respondentData[key]).filter(resp => resp && resp.state !== 'Ejected')} count={count} selected={showSegmentLine} onSelected={handleSelectSegment} colors={lineColor} getData={getAverages} fontSize={segmentFontSize} customButtons={initialState?.customButtons} />
          </div>
          <Typography className={classes.videoName}>{internalVideoName[videoIndex] ? <span><b>Content: </b>{`${internalVideoName[videoIndex]}${configData && initialState?.multiSection ? ` (${videoIndex + 1}/${configData.playlistLength})` : ''}`}</span> : (configData && initialState?.multiSection ? `VIDEO ${videoIndex + 1} OF ${configData.playlistLength}` : '')} {initialState?.dialMode === 'symbol' && (singleView ? <span>{internalVideoName[videoIndex] ? '|' : ''} <b>Segment: </b>{currentSegment === 'all' ? 'Total' : currentSegment}</span> : <span>{internalVideoName[videoIndex] ? '|' : ''} <b>Emoji: </b><span style={{ fontFamily: 'Noto Color Emoji' }}>{currentSymbol}</span> | <b>Segment Group: </b>{currentSegmentGroup === 'all' ? 'Total' : currentSegmentGroup}</span>)}</Typography>
          {/* <div style={{ display: 'flex', flex: 1 }}> */}
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ minWidth: '2.75rem' }}>
            </div>
            <div className="chart-body">
              <div className="chart-container" /*style={{ maxWidth: ratio ? `${1 / ratio * 100}vh` : 0, maxHeight: ratio ? `${ratio * 100}vw` : 0}}*/ >
                {/* <Paper elevation={3} className='resp-paper'>
              {!!respondentKeys.length && <Typography color="primary" className="chart-resp-header">
                DIAL COUNT: {respondentKeys.length}
              </Typography>}
              {!respondentKeys.length && <Skeleton variant="text" className="chart-resp-header" animation="wave" />}
              <div className="chart-resp-buttons">
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      className="resp-list"
                      id="resp-list"
                      itemCount={respondentKeys.length || 500}
                      itemData={respondentNames.filter(r => respondentKeys.includes(r.id))}
                      itemSize={50}
                      width={width}
                      height={height}
                    >
                      {ListRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            </Paper> */}
                <Paper elevation={4} className={classes.chartPaper}>
                  <div className="chart-surface" ref={videoDivRef}>
                    {!((loaded && !saving) && hasStarted) ?
                      <div className="waiting-box">
                        {initialState && <>
                          <Typography variant="h5">{initialState.videoTitle}</Typography>
                          {initialState.scheduledStartTime && <Typography variant="subtitle1">{moment(initialState.scheduledStartTime).format('MMMM Do YYYY, h:mm a')}</Typography>}
                        </>}
                        {(!initialState || hasStarted) ? <CircularProgress style={{ margin: '6px' }} /> : <div style={{ display: 'flex', marginBottom: '1rem' }} />}
                        <Typography style={{ display: 'flex' }}>
                          {initialState ? hasStarted ? saving ? `Saving Changes` : `Loading chart data` : `Waiting for ${projectId ? 'project' : 'session'} to begin` : `Loading ${projectId ? 'project' : 'session'} data`}<span className="loading-ellipsis" />
                        </Typography>
                        {(initialState && !hasStarted) && <Typography style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                          Reminder: To hear the audio, click the <VolumeOffIcon fontSize="small" style={{ margin: '3px' }} /> icon below to unmute.
                        </Typography>}
                      </div>
                      : <DialChart
                        segmentsMap={segmentsMap}
                        currentSegment={currentSegment}
                        currentSegmentGroup={currentSegmentGroup}
                        currentSymbol={currentSymbol}
                        singleView={singleView}
                        isChartViewFull={isChartViewFull}
                        chartRef={pngChartRef}
                        groupedDataRef={groupedDataRef}
                        graphType={graphType}
                        buttonChartRef={pngButtonRef}
                        loaded={loaded && (!!chartData?.length || replay)}
                        onHover={showVideoScene}
                        scoreBase={initialState.dialMode === 'symbol' ? scoreBase : initialState.scoreBase}
                        scorePrecision={scorePrecision}
                        lineType={chartCurve}
                        segmentCounts={count}
                        lineWidth={lineWidth}
                        lineColor={lineColor}
                        videoMarkerColor={markerColor}
                        videoMarkerWidth={markerWidth}
                        chartDomain={chartDomain}
                        onDomainChange={changeDomain}
                        chartStart={videoStartTime}
                        chartEnd={sessionLength ? chartPrecision ? Math.round(sessionLength) : sessionLength : currentVideoTime}
                        chartData={chartData}
                        buttons={chartButtons}
                        customButtons={initialState.customButtons}
                        videoMarkers={videoMarkers[videoIndex]}
                        dialMode={initialState?.dialMode || 'score'}
                        dataKeys={dataKeys}
                        symbolKeys={symbolKeys}
                        showSegmentLine={showSegmentLine}
                        /*showDataLine={showDataLine}*/
                        showMinutes={showMinutes}
                        showMarkerLabels={showMarkerLabels}
                        showSegmentLabels={showSegmentLabels}
                      />
                    }
                    {(!!videoSrc && !videoLoaded) &&
                      <div className="video-loading-box">
                        <CircularProgress />
                        <Typography>
                          Loading video file...
                        </Typography>
                      </div>
                    }
                    {((loaded && !saving) && (initialState && initialState.videoId)) && <div style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      display: hasStarted ? 'flex' : 'none',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: 0,
                      left: 0,
                      zIndex: -1,
                      backgroundColor: '#151515',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      // opacity: videoOpacity
                    }}>
                      <div style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        backgroundColor: '#151515',
                        zIndex: 1,
                        opacity: (1 - videoOpacity)
                      }}></div>
                      <VideoPlayer
                        // onMetadataLoaded={setVideoAspectRatio}
                        resumeTime={videoResumeTime}
                        videoRef={playerRef}
                        videoState={videoState}
                        onTimeUpdate={onVideoTimeChange}
                        onLoaded={onVideoLoaded}
                        onReady={onVideoReady}
                        onPlay={onVideoPlay}
                        onBuffer={handleVideoBuffer}
                        // onLoadingVideo={onVideoLoading}
                        onFinished={onVideoEnded}
                        wm={user.email}
                        wmOpacity={initialState.watermarkOpacity}
                        watermarkStyle={initialState.watermarkStyle}
                        playerId={initialState.playerId}
                        videoId={initialState.videoId}
                        multiSection={initialState.multiSection}
                        videoIndex={videoIndex}
                        sessionId={initialState.id}
                        alreadyStarted={initialState.started}
                        sessionStartTime={sessionStartTime}
                        sessionComplete={initialState.complete || finished}
                        isLive={liveSession}
                        liveStreamStatus={youtubeLiveStatus}
                        videoStreamData={videoStreamData}
                        playbackStartTime={videoStartTime + videoOffset.current}
                        isMuted={muted}
                        fill={true}
                        style={videoStyle}
                      />
                    </div>}
                    {/* {(initialState && !initialState.videoId) && <CanvasVideo videoRef={videoRef} parent={videoDivRef} videoFile={videoSrc} videoState={videoState} mute={muted} onTimeUpdate={onVideoTimeChange} onLoaded={onVideoLoaded} onReady={onVideoReady} onEnd={onVideoEnded} onVideoError={onVideoError} show={showVideo} />} */}
                  </div>
                  {videoMarkers && <div className={classes.sceneMarkers} style={{ opacity: currentScene ? 0.6 : 0 }}>
                    {currentScene}
                  </div>}
                </Paper>
                {/* <Paper elevation={3} className='stats-paper'>
              {true && <Typography color="primary" className="chart-resp-header">
                DIAL SCORES
            </Typography>}
              <StatsPanel loaded={loaded} chartData={chartData} questions={sessionSegments} />
              <ButtonPanel loaded={loaded} buttons={chartData[chartData.length - 1] && chartData[chartData.length - 1]['buttons']} />
            </Paper> */}
              </div>
              <div className='video-slider-box' style={{
                transform: `translateY(${(initialState && initialState.videoId) || videoSrc ? '100%' : '-20%'})`
              }}>
                <Paper className='video-slider' elevation={3}>
                  {(configData && initialState?.multiSection) && <>
                    <IconButton color="inherit" disabled={!((hasStarted && finished) && (videoSrc || ((loadedVideo.current || videoId.current) && (videoLoaded && !videoBuffering)))) || videoIndex < 1} onClick={handlePrevious}>
                      <SkipPreviousIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="inherit" disabled={!((hasStarted && finished) && (videoSrc || ((loadedVideo.current || videoId.current) && (videoLoaded && !videoBuffering)))) || videoIndex >= configData.playlistLength - 1} onClick={handleNext}>
                      <SkipNextIcon fontSize="small" />
                    </IconButton>
                  </>}
                  <IconButton color="inherit" disabled={!((hasStarted && finished) && (videoSrc || ((loadedVideo.current || videoId.current) && (videoLoaded && !videoBuffering))))} onClick={handlePlayback}>
                    {videoState !== 'playing' ? <PlayArrowIcon fontSize="small" /> : <PauseIcon fontSize="small" />}
                  </IconButton>
                  <IconButton color="inherit" disabled={!((hasStarted && finished) && (videoSrc || ((loadedVideo.current || videoId.current) && videoLoaded)) && replay)} onClick={handleStop}>
                    <StopIcon fontSize="small" />
                  </IconButton>
                  <Slider size="small" style={{ width: '90%', padding: '14px 0', marginLeft: '12px' }} value={currentVideoTime} min={videoStartTime + videoOffset.current} max={(sessionLength + videoOffset.current) || currentVideoTime} disabled={!((hasStarted && finished) && (videoSrc || ((loadedVideo.current || videoId.current) && (videoLoaded && !videoBuffering))))} onChange={handleVideoSeek} onChangeCommitted={changeVideoCurrentTime} />
                  <span className={classes.countDown} style={{ fontStyle: videoCountdown ? 'normal' : 'italic' }} onClick={() => setVideoCountdown(prev => prev < 2 ? prev + 1 : 0)}>{(sessionLength || videoDuration) ? formatTime(videoCountdown === 1 ? ((sessionLength || videoDuration) + videoOffset.current) - (currentVideoTime || (videoStartTime + videoOffset.current)) : (currentVideoTime - (!!videoCountdown && (videoStartTime + videoOffset.current))), true) : '\u2011\u2011:\u2011\u2011:\u2011\u2011'}</span>
                  <IconButton color="inherit" disabled={!(videoSrc || ((loadedVideo.current || videoId.current) && videoLoaded))} onClick={(event) => (event.currentTarget.blur(), setMuted(prev => !prev))}>
                    {muted ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
                  </IconButton>
                </Paper>
              </div>
            </div>
            <div style={{ width: 'min-content', minWidth: '2.75rem', display: 'flex', flexFlow: 'column', justifyContent: 'space-between', height: '92%' }}>
              <div>
                {(showChat && !fullScreenMode?.active) && <IconButton color="inherit" onClick={toggleChatPanel}>
                  <Badge color="secondary" variant="dot" invisible={!chatNotification}>
                    {showChat.open ? <SpeakerNotesOffIcon color="chat" style={{ filter: chatNotification ? 'drop-shadow(2px 2px 2px rgba(0,0,0,0.3))' : 'none' }} fontSize="small" /> : <ChatIcon color="chat" style={{ filter: chatNotification ? 'drop-shadow(2px 2px 2px rgba(0,0,0,0.3))' : 'none' }} fontSize="small" />}
                  </Badge>
                </IconButton>}
                {(!fullScreenMode?.active) && <IconButton color="inherit" onClick={toggleDrawer}>
                  <SettingsIcon fontSize="small" />
                </IconButton>}
                <IconButton color="inherit" onClick={toggleFullScreen}>
                  {fullScreenMode.active ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
                </IconButton>
                {(!client && !fullScreenMode.active) && <IconButton color="inherit" onClick={editSession} disabled={!((hasStarted && finished) || loaded)}>
                  <EditIcon fontSize="small" />
                </IconButton>}
                {(dialModeRef.current === 'score' && initialState?.multiSection && hasStarted && finished) && <IconButton color="inherit" onClick={toggleInfoPanel} disabled={!((hasStarted && finished) || loaded)}>
                  <InfoIcon fontSize="small" />
                </IconButton>}
                {(!!initialState?.customButtons?.size && hasStarted) && <IconButton color="inherit" onClick={toggleButtonPanel} disabled={!(hasStarted || loaded)}>
                  <FeedbackIcon fontSize="small" />
                </IconButton>}
                {client && initialState?.zoomMeetingId && <IconButton onClick={joinZoomMeeting} color="inherit">
                  {loadingZoom && <CircularProgress size={'2rem'} style={{ position: 'absolute', zIndex: '1' }} />}
                  <VideocamIcon fontSize='small' />
                </IconButton>}
                <IconButton onClick={handleShowFile} color="inherit" >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
                {client && state?.pid ? <IconButton onClick={handleReturn} color="inherit" >
                  <ReplyIcon fontSize="small" />
                </IconButton> : <></>}

              </div>
              {(!client && !fullScreenMode?.active) && <div>
                <IconButton color="inherit" onClick={handleClickExport} disabled={!((hasStarted && finished) && (!!chartData?.length && loaded))}>
                  <GetAppIcon fontSize="small" />
                </IconButton>
                <Menu
                  id="export-menu"
                  anchorEl={exportAnchorEl}
                  keepMounted
                  open={Boolean(exportAnchorEl)}
                  onClose={handleCloseExport}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => prepareCSV(chartPrecision)}>Export Aggregate CSV</MenuItem>
                  {initialState?.multiSection && <MenuItem onClick={() => prepareCSV(chartPrecision, { full: true })}>Export Aggregate CSV (Full)</MenuItem>}
                  {/* <MenuItem onClick={() => prepareCSV(chartPrecision, { respondents: true })}>Export Respondent CSV</MenuItem> */}
                  {dialModeRef.current === 'score' && [
                    <MenuItem onClick={openTraceViewerDialog}>Export TraceViewer CSV</MenuItem>,
                  ]}
                  <MenuItem onClick={prepareButtonPressExport}>Export Button Reasons CSV</MenuItem>
                  {dialModeRef.current !== 'buttonOnly' && <MenuItem onClick={() => exportChartPNG(false)}>Export Line Chart PNG</MenuItem>}
                  <MenuItem onClick={() => exportChartPNG(true)}>Export Full Chart PNG</MenuItem>
                  {dialModeRef.current === 'score' && [
                    <MenuItem onClick={() => exportTuneOutPNG()}>Export Tune Out % PNG</MenuItem>,
                    <MenuItem disabled={true} onClick={handleCloseExport}>Export Chart PPT</MenuItem>
                  ]}
                </Menu>
              </div>}
            </div>
          </div>
          <Dialog open={traceViewerOpen} onClose={handleCloseTraceViewerDialog}>
            <DialogTitle>TraceViewer Export Column Label</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the desired column label for export:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Export Column Label"
                fullWidth
                onChange={(event) => traceViewerLabel.current = event.target.value}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTraceViewerDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={prepareTraceViewer} color="primary">
                Export
              </Button>
            </DialogActions>
          </Dialog>
          {showFiles && <SessionFiles sessionId={initialState?.id || state?.sessionId} cameFromClientPortal={client} clientToken={id} handleClose={handleHideFiles} open={showFiles} />}
          {showInfoPanel && <StatsPopup open={showInfoPanel} prefix={projectId ? `Project ${projectId}` : `Session ${sessionId}`} handleClose={handleCloseInfoPanel} loaded={(loaded && !saving)} averageFn={headerAverageFn} sortFn={sortData} contentLength={configData.contentLength} showAll={showAll} dialMode={initialState.dialMode || 'score'} dataKeys={dataKeys} virtualDataKeys={virtualDataKeys} symbolKeys={symbolKeys} scorePrecision={scorePrecision} chartPrecision={chartPrecision} respondents={respondentKeys.map(key => respondentData[key]).filter(resp => resp && resp.state !== 'Ejected')} count={count} selected={showSegmentLine} colors={lineColor} />}
          {showButtonPanel && <ButtonReasonsPopup open={showButtonPanel} handleClose={handleCloseButtonPanel} buttons={chartButtons} customButtons={initialState?.customButtons} respondentData={respondentData} />}
          <ChartSettingsDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            dialMode={initialState?.dialMode || 'score'}
            dataKeys={dataKeys}
            symbolKeys={symbolKeys}
            showAll={showAll}
            toggleShowAll={toggleShowAll}
            lineWidth={lineWidth}
            changeLineWidth={changeLineWidth}
            lineColor={lineColor}
            changeLineColor={changeLineColor}
            markerColor={markerColor}
            onMarkerColorChange={onMarkerColorChange}
            markerWidth={markerWidth}
            onMarkerWidthChange={onMarkerWidthChange}
            chartPrecision={chartPrecision}
            changeChartPrecision={changeChartPrecision}
            scorePrecision={scorePrecision}
            changeScorePrecision={changeScorePrecision}
            videoOpacity={videoOpacity}
            onVideoOpacityChange={onVideoOpacityChange}
            segmentFont={segmentFontSize}
            onSegmentFontChange={onSegmentFontChange}
            showMarkerLabels={showMarkerLabels}
            showSegmentLabels={showSegmentLabels}
            toggleShowMarkerLabels={toggleShowMarkerLabels}
            toggleShowSegmentLabels={toggleShowSegmentLabels}
            chartSize={chartSize}
            changeChartSize={changeChartSize}
            graphType={graphType}
            changeGraphType={changeGraphType}
            // count={count}
            toggleShowMinutes={toggleShowMinutes}
            client={client}
            isChartViewFull={isChartViewFull}
            toggleChartView={toggleChartView}
            singleView={singleView}
            toggleView={toggleView}
            segmentsMap={segmentsMap}
            currentSegment={currentSegment}
            currentSegmentGroup={currentSegmentGroup}
            currentSymbol={currentSymbol}
            changeCurrentSegment={changeCurrentSegment}
            changeCurrentSegmentGroup={changeCurrentSegmentGroup}
            changeCurrentSymbol={changeCurrentSymbol}
            aggregationInterval={initialState?.chartConfig?.defaultAggregationInterval || 30}
            resetAllOptions={resetAllOptions}
          />
          {/* <div style={{ display: 'flex' }}>
          {(videoSrc && finished) &&
            <IconButton color="inherit" onClick={() => setShowVideo(prev => !prev)} >
              {!showVideo ? <VideocamIcon /> : <VideocamOffIcon />}
            </IconButton>
          }
          {((initialState && !initialState.videoId) && !videoSrc && finished && ((!!chartData.length && loaded) || replay)) &&
            <IconButton color="inherit" component="label" onClick={(event) => event.target.value = null} >
              <VideoCallIcon />
              <input type="file" accept="video/*" onChange={loadVideo} style={{ display: "none" }} />
            </IconButton>
          }
          {(videoSrc && finished) &&
            <IconButton color="inherit" onClick={() => setVideoSrc(null)} >
              <ClearIcon />
            </IconButton>
          }
        </div> */}
          {/* {((!finished || replay) && loaded && !!chartData.length) &&
          <DialContainer respondents={respondentKeys} dialData={chartData[chartData.length - 1] || []} selected={showDataLine} select={handleSelect} />
        } */}
          {/*</div>*/}
        </FullScreen>
        {(chimeChatData && !!(allowedChatChannels.length || hasChat)) && <ChatDrawer open={(showChat && showChat.open)} setOpen={setShowChat} getChatCredentials={getChatCredentials} chatInstance={chimeChatData.instance} chatChannels={allowedChatChannels} onChannelMessage={showChatNotification} />}
      </div >
    </Root>
  );

})

export default SessionDialPage;
